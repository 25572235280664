let custom = {
    intersect: function(a,b){
        return !(
            a.x2 < b.x1 || 
            b.x2 < a.x1 || 
            a.y2 < b.y1 ||
            b.y2 < a.y1
        )
    },
    is_json: function(json){
        try{
            JSON.parse(json);
        }catch(e){
            return false;
        }
        return true;
    },
    arraymove: function(arr, from, to){
        var element = arr[from];
        arr.splice(from, 1);
        arr.splice(to, 0, element);
        return arr;
    },
    currency: function(a,b){
        try{
            if(isNaN(a*1)){
                return a;
            }
            a = a*1;
            if(b === "full"){
                let c = a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split(".");
                let i = 0;
                let d = '';
                while(c.length-1 > i){
                    d = d+""+c[i];
                    i++;
                }
                return d;
            }
            return a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }catch(error){
            return a;
        }
    },
    getCookie: function(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    setCookie: function(key,value){
        document.cookie = key+"="+value+"; path=/";
    },
    toTitleCase:(str)=>{
        if(!str){
            return "";
        }
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    },
    base64tou8: function(base64){
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    },
    showFile: function(blob,filename) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        let newBlob = new Blob([blob], {type: "application/pdf"})

        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download=filename;
        link.click();
        setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    },
    random: function(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
    },
    random_string: function(len){
        let base = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890";
        let str = "";
        let i = 0;
        while(len > i){
            str = str + base[this.random(0,61)];
            i++;
        }
        return str;
    },
    getUrlParameter(name,url) {
        if(!url){
            url = window.location.hash;
        }
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(url);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}

export default custom;