import { axios } from "../main/axios";
import { ImageCropModal } from "./ImgCropModal";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  notification,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const UserStampModal = ({ visible, onCancel, userID, next }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [cropped, setCropped] = useState(null);

  // const formInst = Form.useForm();
  const formRef = useRef();

  const addStamp = async (data) => {
    const { description, shortname, type, file } = data;
    const form = new FormData();
    form.append("file", file);

    const res = await axios.post(
      `/admin/api/user/uploadimg/${userID}/${type}?description=${description}&shortname=${shortname}`,
      form
    );

    console.log("res", res);

    notification.success({
      description: "Successfully uploaded.",
      placement: "topRight",
      duration: 3000,
    });
    onCancel();
    return res.data;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { description, shortname, type } = data;
    const file = dataURLtoFile(cropped, `${Date.now()}.png`);
    console.log(data);

    const body = {
      description,
      shortname,
      type,
      file,
    };

    await addStamp(body);

    next && next();

    setIsLoading(false);
  };

  const onChange = async (event) => {
    const { file } = event;

    const res = await toBase64(file.originFileObj);

    const _type = formRef.current.getFieldValue("type");
    if (_type) setType(_type);

    setFile(res);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCropped = (dataURL) => {
    // console.log("blobbed", dataURL);

    setCropped(dataURL);
  };

  useEffect(() => {
    if (visible) console.log(userID);
    else {
      formRef.current && formRef.current.resetFields();
      setCropped(null);
    }
  }, [visible]);

  return (
    <Modal
      title="Stamp add"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" onFinish={onSubmit} ref={formRef}>
        <Form.Item
          label="Short name"
          name="shortname"
          rules={[
            {
              required: true,
              message: "Please input your shortname!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input your description!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label="Image type"
          name="type"
          rules={[
            {
              required: true,
              message: "Please input your shortname!",
            },
          ]}
          initialValue="eseal"
        >
          <Select>
            <Select.Option value="eseal">eseal</Select.Option>
            <Select.Option value="signature">signature</Select.Option>
            <Select.Option value="initials">initials</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="file"
          label="File"
          rules={[
            {
              required: true,
              message: "Please upload your file!",
            },
          ]}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              minHeight: cropped ? "250px" : "unset",
            }}
          >
            <Upload
              // listType="picture-card"
              maxCount={1}
              // fileList={[]}
              onChange={onChange}
              // onPreview={onPreview}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            {cropped && <Image src={cropped} width="50%" />}
          </div>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>

      <ImageCropModal
        isOpen={file !== null}
        onClose={() => setFile(null)}
        file={file}
        type={type}
        saveResult={handleCropped}
      />
    </Modal>
  );
};
