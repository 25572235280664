import React from 'react';
import moment from 'moment';

class example extends React.Component{
    render(){
        let { value } = this.props;
        return (
            <div>
                {moment(value).format("YYYY-MM-DD HH:mm")}
            </div>
        );
    }
}

export default example;