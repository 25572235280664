import { Input } from "antd";
import TextEdit from "../comps/Fields/TextEdit";

let globals = {
  hostname: window.location.hostname,
  origin: window.location.origin,
  logo: "/2.png",
  manage: 1,
  filename: "",
  color: [
    "rgba(0, 92, 185, 0.7)",
    "rgba(251, 176, 39, 0.7)",
    "rgba(255, 110, 110, 0.7)",
    "rgba(59, 193, 76, 0.7)",
    "rgba(35, 193, 186, 0.7)",
    "rgba(240, 91, 243, 0.7)",
    "rgba(178, 187, 39, 0.7)",
    "rgba(255, 225, 77, 0.7)",
    "rgba(51, 255, 85, 0.7)",
    "rgba(239, 222, 205, 0.7)",
    "rgba(59, 122, 87, 0.7)",
    "rgba(255, 191, 0, 0.7)",
    "rgba(102, 0, 68, 0.7)",
    "rgba(114, 160, 193, 0.7)",
  ],
  roles: [
    { key: 0, name: "SIGN", icon: "pen", actionrole: "signer" },
    { key: 1, name: "APPROVE", icon: "user", actionrole: "approver" },
    { key: 2, name: "COPY", icon: "envelope", actionrole: "copy" },
  ],
  contacts: [],
  sizes: {
    signature: { width: 200, height: 80 },
    date: { width: 79, height: 23 },
    name: { width: 198, height: 23 },
    company: { width: 198, height: 23 },
    email: { width: 247, height: 23 },
    title: { width: 198, height: 23 },
  },
  fields: [
    {
      name: "Signature",
      icon: "pen",
      key: 1,
      unique_key: "signature",
      drag_txt: "Signature",
      style: { width: 200, height: 80 },
    },
    {
      name: "Document Date",
      icon: "calendar",
      key: 2,
      unique_key: "date",
      drag_txt: "Date",
      style: { width: 79, height: 23 },
    },
    {
      name: "Name",
      icon: "user",
      key: 3,
      unique_key: "name",
      drag_txt: "Name",
      style: { width: 198, height: 23 },
    },
    {
      name: "Email",
      icon: "envelope",
      key: 4,
      unique_key: "email",
      drag_txt: "Email",
      style: { width: 198, height: 23 },
    },
    {
      name: "Company",
      icon: "building",
      key: 5,
      unique_key: "company",
      drag_txt: "Company",
      style: { width: 247, height: 23 },
    },
    {
      name: "Title",
      icon: "briefcase",
      key: 6,
      unique_key: "title",
      drag_txt: "Title",
      style: { width: 198, height: 23 },
    },
  ],
  customFields: [
    {
      name: "Text",
      icon: "font",
      key: 7,
      unique_key: "shortText",
      drag_txt: "Text",
      style: { width: 257, height: 23 },
      max: { char: 31 },
      value: "",
    },
    // {name:"Formula",icon:"subscript",key:8,unique_key:"formula",drag_txt:"Formula",style:{width:198,height:23}},
    // {name:"Image",icon:"images",key:9,unique_key:"image",drag_txt:"Images",style:{width:198,height:23}},
    {
      name: "Web link",
      icon: "link",
      key: 10,
      unique_key: "link",
      drag_txt: "Link",
      style: { width: 257, height: 23 },
      max: { char: 31 },
      value: "",
    },
    {
      name: "Text",
      icon: "font",
      key: 11,
      unique_key: "mediumText",
      drag_txt: "Text",
      style: { width: 385, height: 23 },
      max: { char: 48 },
      value: "",
    },
    {
      name: "Text",
      icon: "font",
      key: 12,
      unique_key: "largeText",
      drag_txt: "Text",
      style: { width: 771, height: 23 },
      max: { char: 98 },
      value: "",
    },
    {
      unique_key: "yourSignature",
      drag_txt: "Your signature",
      style: { width: 230, height: 60 },
      url: "https://signaturely.com/wp-content/uploads/2020/04/upward-angle-signaturely.svg",
      svgurl: true,
    },
    {
      unique_key: "yourStamp",
      drag_txt: "Your stamp",
      style: { width: 230, height: 60 },
      url: "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/approved-date-stamp-design-template-9b26102e07995e40e695d07f9aa5fae3_screen.jpg?ts=1590769712",
      svgurl: true,
    },
  ],
  m1: {
    title: "Active requests",
    menu: [
      {
        type: "m1",
        key: 0,
        href: "#/manage/0/m1/0",
        icon: "inbox",
        name: "Received",
        datas_key: "received",
        filter: "received",
      },
      {
        type: "m1",
        key: 1,
        href: "#/manage/1/m1/0",
        icon: "paper-plane",
        name: "Sent",
        datas_key: "sent",
        filter: "sent",
      },
    ],
  },
  m2: {
    title: "Transactions",
    menu: [
      {
        type: "m2",
        key: 0,
        href: "#/manage/0/m2/0",
        icon: "exclamation-circle",
        name: "All",
        datas_key: "all",
        filter: "all",
      },
      {
        type: "m2",
        key: 1,
        href: "#/manage/1/m2/0",
        icon: "exclamation-triangle",
        name: "Rejected",
        datas_key: "reject",
        filter: "reject",
      },
      {
        type: "m2",
        key: 2,
        href: "#/manage/2/m2/0",
        icon: "check",
        name: "Completed",
        datas_key: "complete",
        filter: "complete",
      },
      {
        type: "m2",
        key: 3,
        href: "#/manage/3/m2/0",
        icon: "calendar",
        name: "Archived",
        datas_key: "archive",
        filter: "archive",
      },
      {
        type: "m2",
        key: 4,
        href: "#/manage/4/m2/0",
        icon: "inbox",
        name: "Waiting",
        datas_key: "waiting",
        filter: "waiting",
      },
    ],
  },
  user: { detail: "init" },
};
let setStore = function (name, value) {
  globals[name] = value;
};
let getStore = function (name, ini) {
  if (!globals[name]) {
    if (ini) {
      return ini;
    }
    return {};
  }
  return globals[name];
};

export { globals, setStore, getStore };
