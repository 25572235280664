import { React } from "react";
import { Modal, Form, Input, notification } from "antd";
import { axios } from "../main";
import { useRef } from "react";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { useState } from "react";

const ShareRoot = ({ params }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const close = () => {
    let hash = window.location.hash;
    hash = hash.replace("share", "0");
    window.location.hash = hash;
  };

  const share = (data) => {
    setLoading(true);
    axios
      .post("client/api/doc/share", {
        ...data,
        docid: params.popfilter,
        msisdn: "",
      })
      .then(({ data }) => {
        notification.success({
          message: data.message,
        });
        formRef.current.resetFields();
        close();
      })
      .catch(({ data }) => notification.error({ message: data.message }))
      .finally(() => {
        setLoading(false);
      });
  };

  //   "docs_id":1,
  //     "email":"argentinaa22@gmail.com",
  //     "msisdn":"123"

  return (
    <Modal
      visible={params.pop === "share"}
      onCancel={close}
      title="Share document"
      footer={null}
    >
      <div>
        {loading && (
          <div className="loader">
            <LoadingOutlined />
          </div>
        )}
        <Form onFinish={share} ref={formRef}>
          <Form.Item
            // label="Email"
            name="email"
            rules={[{ required: "true", message: "Please input your email!" }]}
          >
            <Input
              placeholder="email"
              addonBefore={<MailOutlined />}
              type="email"
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <button type="submit" bg_color="5" className="btn action_button">
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ShareRoot;
