import React, {useState} from "react";
import { getStore } from "@/main";
import {
  EditFilled,
  UserOutlined,
  MailOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { roleChange, partsRemove } from '../../store/actions/'

const CheckRole = (props) => {

  const roles = getStore("roles");
  const dispatch = useDispatch()

  const changeRole = (val) => {
    dispatch(roleChange({index: props.index, value: val}))
  }

  const icon_map = {
    pen: <EditFilled />,
    user: <UserOutlined />,
    envelope: <MailOutlined />,
  };

  const [selected, setSelected] = useState(
    { actionrole: "signer", icon: "pen", key: 0, name: "SIGN" }
  )

  const menu = (
    <Menu>
      {roles.map((per, i) => {
        return (
          <Menu.Item
            key={per.key}
            onClick={() => {
              changeRole(per);
              setSelected(per);
            }}
          >
            <div className="checked_role_per">
              {icon_map[per.icon]}&nbsp; {per.name}
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <div className="checked_role">
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="checked_role_per_active">
        {icon_map[selected.icon]}&nbsp; {selected.name}{" "}
          &nbsp;
          <CaretDownOutlined />
        </div>
      </Dropdown>
    </div>
  );

}

export default CheckRole;
