import {
    CHANGE_SIZE,
    BOX_ADD
} from '../type'
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 10 });

const boxType  = {
    signature:[{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Signature",
        height: 80,
        icon: "pen",
        key: 1,
        left: 88,
        msisdn: "35847004004",
        name: "Text",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 46,
        type: "signature",
        uid: uid(),
        unique_key: "signature",
        width: 165,
        x1: 88,
        y1: 601
    }],
    date: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "date",
        height: 23,
        icon: "calender",
        key: 1,
        left: 88,
        msisdn: "35847004004",
        name: "Document Date",
        page: 1,
        part_index: 0,
        style: {
            height: 78,
            width: 198
        },
        top: 143,
        type: "date",
        uid: uid(),
        unique_key: "date",
        width: 79,
        x1: 88,
        y1: 601
    }],
    name: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Name",
        height: 23,
        icon: "user",
        key: 3,
        left: 119.5,
        msisdn: "35847004004",
        name: "Name",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 46,
        type: "name",
        uid: uid(),
        unique_key: "name",
        width: 198,
        x1: 119.5,
        y1: 796
    }],
    email: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Email",
        height: 23,
        icon: "envelope",
        key: 4,
        left: 119.5,
        msisdn: "35847004004",
        name: "Email",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 235,
        type: "email",
        uid: uid(),
        unique_key: "email",
        width: 198,
        x1: 88,
        y1: 601
    }],
    company: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Company",
        height: 23,
        icon: "building",
        key: 5,
        left: 133.5,
        msisdn: "35847004004",
        name: "Company",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 247
        },
        top: 273,
        type: "company",
        uid: uid(),
        unique_key: "company",
        width: 247,
        x1: 133.5,
        y1: 703
    }],
    title: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Title",
        height: 23,
        icon: "briefcase",
        key: 6,
        left: 181,
        msisdn: "35847004004",
        name: "Title",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 113,
        type: "title",
        uid: uid(),
        unique_key: "title",
        width: 198,
        x1: 181,
        y1: 863
    }],
    shortText: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Text",
        height: 23,
        icon: "font",
        key: 1,
        left: 180,
        msisdn: "35847004004",
        name: "Text",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 46,
        type: "shortText",
        uid: uid(),
        unique_key: "shortText",
        width: 198,
        x1: 180,
        y1: 822
    }],
    link: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Link",
        height: 23,
        icon: "link",
        key: 1,
        left: 198,
        msisdn: "35847004004",
        name: "Web link",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 198
        },
        top: 46,
        type: "Link",
        uid: uid(),
        unique_key: "Link",
        width: 198,
        x1: 198,
        y1: 693
    }],
    mediumText: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Text",
        height: 23,
        icon: "font",
        key: 11,
        left: 362,
        msisdn: "35847004004",
        name: "Text",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 250
        },
        top: 391,
        type: "mediumText",
        uid: uid(),
        unique_key: "mediumText",
        width: 250,
        x1: 362,
        y1: 585
    }],
    largeText: [{
        color: "rgba(255, 110, 110, 0.7)",
        drag_txt: "Text",
        height: 23,
        icon: "font",
        key: 12,
        left: 106,
        msisdn: "35847004004",
        name: "Text",
        page: 1,
        part_index: 0,
        style: {
            height: 23,
            width: 450
        },
        top: 491,
        type: "largeText",
        uid: uid(),
        unique_key: "largeText",
        width: 450,
        x1: 106,
        y1: 485
    }],
}

const initialState  = {
    boxes: [],
    focused:{}
}

// let BOX = this.props.boxes
// let per = {...stamps[this.state.active_i]};
// per.width = size.width;
// per.height = size.height;
// stamps[this.state.active_i] = per;
// this.props.setBoxes(stamps);

export default function(state=initialState, action){
    switch(action.type){
        case CHANGE_SIZE:
            return {width: 155}
        case BOX_ADD:
            const { unique_key } = action.payload
            return  { ...state, boxes: [...state.boxes, action.payload] }
        // case PARTS_RESET:
        //     return initialState
        // case PARTS_ADD:
        //     return {...state, parts:[...state.parts, action.payload]}
        // case PARTS_REMOVE:
        //     return {...state, parts: state.parts.filter(item => item.key != action.payload)}
        // case ROLE_CHANGE:
        //     const {index, value} = action.payload
        //     const idx = state.parts.findIndex(item => item.key === index)
        //     let tmp = state.parts[idx]
        //     tmp.role = value.role
        //     tmp.actionrole = value.actionrole
        //     if (value.name === "SIGN") {
        //         tmp.name = 'Signer'
        //     } else if(value.name === "APPROVE") {
        //         tmp.name = 'Approver'
        //     } else {
        //         tmp.name = 'Copy receiver'
        //     }
        //     if (value.name !== "SIGN") {
        //         tmp.can_sign = false;
        //     }
        //     return {...state, parts:[
        //         ...state.parts.slice(0, idx),
        //         tmp,
        //         ...state.parts.slice(idx + 1),
        //     ]}
        // case SET_FOCUS:
        //     return {...state, focused: action.payload}
        default:
            return state
    }
}