import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class example extends React.Component {
  render() {
    let { data, style, name } = this.props;
    return (
      <a
        href={data.href}
        className="filter_container"
        disabled_link="1"
        boxsize={data.size ? data.size : ""}
        active={data.active ? 1 : 0}
        style={style}
      >
        <div className="filter_title">{name}</div>
        <div className="filter_body">
          <div className="filter_icon">
            <FontAwesomeIcon
              icon={["fas", data.icon]}
              style={{ color: data.color }}
            />
          </div>
          <div className="filter_data">{data.body}</div>
        </div>
      </a>
    );
  }
}

export default example;
