import { Modal, notification } from "antd";
import React, { useEffect } from "react";
import {
  FileMarkdownOutlined,
  FileOutlined,
  FileProtectOutlined,
  LoadingOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { axios, custom, getStore } from "../main";
import { useState } from "react";

const DownloadRoot = ({ params }) => {
  const [loading, setLoading] = useState("0");

  let doc = getStore("filename");

  const close = () => {
    let hash = window.location.hash;
    hash = hash.replace("download", "0");
    window.location.hash = hash;
  };

  const downloadReceipt = async () => {
    const name = doc.name.replace(".pdf", ".receipt.pdf");
    try {
      setLoading("1");
      const res = await axios.post("/client/api/doc/receipt/get", {
        docid: params.popfilter,
      });

      let blob = custom.base64tou8(res.data);
      custom.showFile(blob, name);
      close();
      setLoading("0");
    } catch (err) {
      notification.error({ message: err?.data?.message });
      setLoading("0");
    }
  };

  const download = (isComplete) => {
    let name = isComplete ? doc.name.replace(".pdf", ".signed.pdf") : doc.name;
    setLoading("1");
    axios
      .post(
        "/client/api/doc/file/get",
        isComplete
          ? { docid: params.popfilter, complete: true }
          : { docid: params.popfilter }
      )
      .then(({ data }) => {
        let blob = custom.base64tou8(data);
        custom.showFile(blob, name);
        close();
      })
      .catch(({ data }) => {
        notification.error({ message: data.message });
      })
      .finally(() => {
        setLoading("0");
      });
  };

  return (
    <Modal
      visible={params.pop === "download"}
      onCancel={close}
      title="Document download"
      footer={null}
      width="600px"
    >
      <div className="download_options" isLoading={loading}>
        <div className="download_options_loader">
          <LoadingOutlined />
        </div>
        <div className="download_option">
          <span>Original document</span>
          <div className="download_option_bg">
            <FileOutlined />
          </div>
          <button
            bg_color="5"
            className="btn action_button"
            onClick={() => download(false)}
          >
            Download
          </button>
        </div>
        <div
          className="download_option"
          style={{ borderLeft: "1px solid #eee" }}
        >
          <span>Signed document</span>
          <div className="download_option_bg">
            <FileProtectOutlined />
          </div>
          <button
            bg_color="5"
            className="btn action_button"
            disabled={
              !(doc?.status === "COMPLETE" || doc?.status === "ARCHIVED")
            }
            onClick={() => download(true)}
          >
            Download
          </button>
        </div>
        <div
          className="download_option"
          style={{ width: "100%", columnSpan: "2" }}
        >
          <span>Transaction certificate</span>
          <div className="download_option_bg">
            <FileMarkdownOutlined />
          </div>
          <button
            bg_color="5"
            className="btn action_button"
            disabled={
              !(doc?.status === "COMPLETE" || doc?.status === "ARCHIVED")
            }
            onClick={() => downloadReceipt()}
          >
            Download
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadRoot;
