import React from "react";
import {
  CloseOutlined,
  CheckSquareFilled,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { Step0, Step1, Step2, Step3, Step4 } from "@/comps/upload/";
import { Switch, Route } from "react-router-dom";
import { getStore } from "@/main";
import { Translate } from "../../translate";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.user = getStore("user");
  }
  state = { reload: 0, stamps: [] };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };
  data = {
    docs_id: 0,
    txn_id: 0,
    file: false,
    parts: [],
    stamps: [],
  };
  trig = [0, 0, 0, 0, 0];
  steps = [
    { name: "Upload", key: 1 },
    { name: "Participants", key: 2 },
    { name: "Customizations", key: 3 },
    { name: "Preview", key: 4 },
    { name: "Finish", key: 5 },
  ];
  funcs = {
    close: () => {
      this.setState({ stamps: [] });
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/0/${p.popfilter}`;
    },
    back: () => {
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/${
        p.popfilter - 1
      }`;
    },
    next: () => {
      let p = this.props.params;
      this.trig[p.popfilter]++;
      this.reload();
    },
    start: () => {
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/0`;
    },
  };
  componentDidUpdate(pp, ps) {
    if (pp.params.pop == "0" && this.props.params.pop === "upload") {
      this.data = {
        docs_id: 0,
        txn_id: 0,
        file: false,
        parts: [],
      };
    }
  }
  componentDidMount() {
    this.funcs.start();
  }
  setData = (data) => {
    let tmp = { ...this.data };
    tmp.parts = data;
  };

  setStamps = (stamps) => {
    this.setState({ stamps: stamps });
  };
  render() {
    let p = this.props.params;
    let { trig } = this;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");
    return (
      <div className="upload_container" is_show={p.pop === "upload" ? 1 : 0}>
        {p.pop === "upload" ? (
          <>
            <div
              className="upload_header"
              bg_color="2"
              active_step={p.popfilter}
            >
              <div
                className="upload_header_close flex_center"
                onClick={this.funcs.close}
                color="3"
              >
                <CloseOutlined />
              </div>
              <div className="upload_header_back">
                <div
                  className="btn flex_center"
                  onClick={this.funcs.back}
                  bg_color="3"
                >
                  {Translate[ln].NewDoc.Back}
                </div>
              </div>
              <div
                className="upload_header_ind_container flex_center"
                color="3"
              >
                {this.steps.map((per, i) => {
                  return (
                    <div
                      className="upload_header_ind"
                      key={per.key}
                      active={p.popfilter >= i ? 1 : 0}
                    >
                      <CheckSquareFilled active="1" />
                      <CheckSquareOutlined active="0" />
                      <span className="upload_header_ind_label">
                        {/* {per.name} */}
                        {Translate[ln].NewDoc.Upload[per.name]}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="upload_header_next">
                <div
                  className="btn flex_center"
                  bg_color="3"
                  onClick={this.funcs.next}
                >
                  {p.popfilter == 4
                    ? Translate[ln].NewDoc.Send
                    : Translate[ln].NewDoc.Next}
                </div>
              </div>
            </div>
            <div className="upload_body">
              <Step0
                params={p}
                data={this.data}
                trigger={trig[0]}
                start={this.funcs.start}
                onChange={(file, docs_id, txn_id) => {
                  this.data.file = file;
                  this.data.docs_id = docs_id;
                  this.data.txn_id = txn_id;
                }}
              />
              <Step1
                params={p}
                data={this.data}
                trigger={trig[1]}
                start={this.funcs.start}
                onChange={(parts) => {
                  this.data.parts = parts;
                }}
              />
              <Step2
                params={p}
                data={this.data}
                trigger={trig[2]}
                start={this.funcs.start}
                setData={this.setData}
                setStamps={this.setStamps}
                stamps={this.state.stamps}
              />
              <Step3
                params={p}
                data={this.data}
                trigger={trig[3]}
                start={this.funcs.start}
                stamps={this.state.stamps}
              />
              <Step4 params={p} data={this.data} trigger={trig[4]} />
            </div>
            <div className="upload_footer">
              <div className="upload_footer_powered">
                Powered by&nbsp;<b>Vsign</b>
              </div>
              <div className="upload_footer_menu_container"></div>
              <div className="upload_footer_copy">
                Copyright © 2021 Vsign All rights reserved
              </div>
            </div>
          </>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default example;
