import { Button, Drawer, Input, notification, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { axios, custom } from "@/main/";
import {
  LoadingOutlined,
  SecurityScanTwoTone,
  SendOutlined,
} from "@ant-design/icons";
import { PreviewRoot } from "@/comps";
import ContactTemplate from "./ContactTemplate";
import Step4 from "@/comps/upload/Step4";

const steps = [
  {
    title: "Set participants",
  },
  {
    title: "Preview",
  },
  {
    title: "Share",
  },
];

const CancelRoot = ({ params, getList }) => {
  const [loading, setLoading] = useState(false);
  const [tmplateInfo, setTmplateInfo] = useState(null);
  const [file, setFile] = useState(null);
  const [step, setStep] = useState(0);
  const [mailValues, setMailValues] = useState({ desc: "", subject: "" });
  const [pageSize, setPageSize] = useState(null);

  const close = () => {
    setStep(0);
    let hash = window.location.hash;
    hash = hash.replace(`templates/1/${step}/${params.id}`, "templates/0/0/0");
    window.location.hash = hash;
  };

  const stepHandler = () => {
    let hash = `templates/1/${step}/${params.id}`;

    switch (step) {
      case 0: {
        if (tmplateInfo.signers.some((el) => el.msisdn.length === 0))
          notification.warning({
            message: "Please fill the all participants",
            duration: 2,
          });
        else {
          hash = `templates/1/${step + 1}/${params.id}`;
          setStep(step + 1);
        }
        break;
      }

      case 1: {
        hash = `templates/1/${step + 1}/${params.id}`;
        setStep(step + 1);
        break;
      }

      default:
        createDoc();
    }

    window.location.hash = hash;
  };

  const backhandler = () => {
    let hash = `templates/1/${step - 1}/${params.id}`;
    window.location.hash = hash;
    setStep(step - 1);
  };

  const renderPage = (e) => {
    const num = e ? e : step;
    switch (num) {
      case 0:
        return (
          <div className="template_preview">
            <div className="sidebar">
              <span>Template name</span>
              <Input value={tmplateInfo?.name} readOnly />
              <span>Participants</span>
              {tmplateInfo?.signers?.map((el, idx) => (
                <ContactTemplate
                  data={el}
                  key={idx}
                  onChange={(key, val) => contactsHandler(idx, key, val)}
                  defaults={{
                    email: tmplateInfo?.signers[idx]?.email,
                    msisdn: tmplateInfo?.signers[idx]?.msisdn,
                  }}
                />
              ))}
            </div>

            <PreviewRoot
              hide_add={1}
              file={file}
              boxes={tmplateInfo?.stamps}
              setBoxes={(stamps) => {
                console.log(stamps);
              }}
              locked={true}
              isDisabled={true}
              editable={true}
            />
          </div>
        );
      case 1:
        return (
          <div className="step1_container relafull">
            <div className="step1_detail">
              <div className="step1_user_detail" style={{ marginTop: 20 }}>
                <div className="step1_user_detail_title">
                  {tmplateInfo?.name}{" "}
                </div>
                {/* <div className="step1_user_detail_body">
              <div className="user_dropdown_name">{user.kdss_name}</div>
              <div className="user_dropdown_mobile">{user.kdss_msisdn}</div>
              <div className="user_dropdown_email" color="1">
                {user.kdss_email}
              </div>
            </div> */}
              </div>
              <div className="parts_scroll_area">
                {tmplateInfo?.signers?.map((per, i) => {
                  return (
                    <div
                      key={i}
                      className="step1_user_detail"
                      style={{ marginTop: 20 }}
                    >
                      <div
                        className="step1_user_detail_title"
                        style={{
                          background: per.bg_color,
                          textTransform: "capitalize",
                        }}
                      >
                        {per?.actionrole}
                      </div>
                      <div className="step1_user_detail_body">
                        <div className="user_dropdown_name">{per.name}</div>
                        <div className="user_dropdown_mobile">{per.msisdn}</div>
                        <div className="user_dropdown_email" color="1">
                          {per.email}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="step3_body">
              <div className="absfull">
                <PreviewRoot
                  hide_add={1}
                  file={file}
                  // pageChange={(page) => {
                  //   this.page = page;
                  //   this.renderTools();
                  // }}

                  locked={true}
                  setBoxes={(stamps) => {
                    console.log(stamps);
                  }}
                  boxes={tmplateInfo?.stamps}
                  lockInputs={true}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <Step4
            {...{
              params,
              flag: true,
              onChange: (data) => setMailValues(data),
              trigger: "2",
              triggerKey: "tmpTrigger",
            }}
          />
        );
      default:
        return <div>default</div>;
    }
  };

  const getInfo = () => {
    axios
      .post("client/api/doc/template/get", { id: params.id })
      .then(({ data }) => {
        setTmplateInfo(data.detail);

        getFile(data.detail?.doc_id, data.detail?.file_name);
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: err.data.message, duration: 4 });
      });
  };

  const getFile = (id, name) => {
    axios
      .post("client/api/doc/template/file/get", { doc_id: id })
      .then(({ data }) => {
        let nf = new File([custom.base64tou8(data)], name, {
          type: "application/pdf",
          lastModified: new Date(),
        });
        console.log("new file", nf);

        setFile(nf);
      })
      .catch((err) => {
        notification.error({ message: "Алдаа гарлаа", duration: 4 });
        console.error(err);
      });
  };

  useEffect(() => {
    if (params.popfilter === "1") {
      getInfo();
    } else getList && getList();
  }, [params.popfilter]);

  useEffect(() => {
    const getContainer = () => {
      let _container = document.querySelector(".boxes_drag_area");

      if (_container) {
        setPageSize({ h: _container.clientHeight, w: _container.clientWidth });
        console.log("container found", _container);
      } else {
        setTimeout(getContainer, 1000);
      }
    };

    if (file) getContainer();
  }, [file]);

  const createDoc = () => {
    let ids = tmplateInfo.signers.map((el) => el.msisdn);
    if (ids.indexOf("") !== -1) {
      notification.error({
        message: "Please fill the all participants!",
        duration: 4,
      });
      return;
    }
    setLoading(true);
    let reqFile = new FormData();
    reqFile.append("file", file);

    axios
      .post("client/api/doc/create", reqFile)
      .then(({ data }) => {
        let docid = data.docid;

        let stamps = tmplateInfo.stamps?.map((el, idx) => {
          let id = el.uid;
          let signer = tmplateInfo.signers.filter((el) => el.user_id === id)[0];

          el.msisdn = signer.msisdn;

          return el;
        });

        axios
          .post("client/api/doc/signer/add", {
            docid: docid,
            recs: tmplateInfo.signers,
          })
          .then(({ data: data1 }) => {
            let container = document.querySelector(".boxes_drag_area");
            axios
              .post("client/api/doc/stamp/add", {
                docid: docid,
                stamps: stamps,
                pageheight: pageSize.h,
                pagewidth: pageSize.w,
              })
              .then(({ data: data2 }) => {
                axios
                  .post("/client/api/doc/startsign", {
                    docid: docid,
                    desc: mailValues.desc,
                    subject: mailValues.subject,
                    txnid: data.txnid,
                  })
                  .then(({ data: data3 }) => {
                    notification.success({
                      message: data3.message,
                      duration: 4,
                    });
                    close();
                  })
                  .catch((err) =>
                    notification.error({
                      message: err.data.message,
                      duration: 4,
                    })
                  )
                  .finally(() => setLoading(false));
              })
              .catch((err) => {
                notification.error({ message: "Алдаа гарлаа", duration: 4 });
                console.error(err);
                setLoading(false);
              });
          })
          .catch((err) => {
            notification.error({ message: "Алдаа гарлаа", duration: 4 });
            console.error(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        notification.error({ message: "Алдаа гарлаа", duration: 4 });
        console.error(err);
        setLoading(false);
      });
  };

  const renderTitle = () => {
    return (
      <>
        {step > 0 && <Button onClick={backhandler}>Prev</Button>}
        <Steps
          current={step}
          onChange={(e) => {
            console.log(e);
            setStep(e);
          }}
          responsive
        >
          {steps.map((item, idx) => (
            <Steps.Step
              key={item.title}
              title={item.title}
              disabled={step < idx}
            />
          ))}
        </Steps>
        {
          <Button onClick={() => step < 3 && stepHandler()}>
            {step == 2 ? "Send" : "Next"}
          </Button>
        }
      </>
    );
  };

  const contactsHandler = (idx, key, value) => {
    let recs = tmplateInfo;
    recs.signers[idx][key] = value;
    setTmplateInfo(tmplateInfo);
  };

  console.log("params", params);

  return (
    <Drawer
      title={
        // <div
        //   style={{
        //     display: "flex",
        //     justifyContent: "space-between",
        //     marginRight: "32px",
        //   }}
        // >
        //   <span>
        //     {tmplateInfo?.name ?? "Template"}{" "}
        //     {step === 2 && <Button onClick={openShareSection}>Back</Button>}
        //   </span>

        //   {step === 1 ? (
        //     <Button onClick={openShareSection}>Next</Button>
        //   ) : (
        //     <Button onClick={createDoc}>Send</Button>
        //   )}
        // </div>
        <div className="drawer_title">{renderTitle()}</div>
      }
      visible={params.popfilter === "1"}
      onClose={close}
      placement="bottom"
      height="100vh"
      className="custom_drawer"
    >
      {loading && (
        <div className="loader">
          <LoadingOutlined />
        </div>
      )}
      {renderPage()}
    </Drawer>
  );
};

export default CancelRoot;
