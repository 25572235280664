import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { custom, setStore } from "@/main";
import { Button } from "antd";
import { axios, getStore } from "../main";
import { Translate } from "../translate";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }
  state = {
    visible: 0,
    lang: "en",
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (e) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.setState({ visible: 0 });
    }
  };
  handleSettings = () => {
    let hash = window.location.hash;

    if (hash.indexOf("home") !== -1) hash = hash.replace("home", "settings");
    else hash = hash.replace("manage", "settings");
    window.location.hash = hash;
  };

  changeLang = () => {
    this.props.updateCounter();
    if (getStore("lang") !== "en") {
      setStore("lang", "en");
      this.setState({ lang: "en" });
      window.localStorage.setItem("vsign_lang", "en");
      axios.defaults.headers.common["Accept-Language"] = "en";
    } else {
      setStore("lang", "mn");
      this.setState({ lang: "mn" });
      window.localStorage.setItem("vsign_lang", "mn");
      axios.defaults.headers.common["Accept-Language"] = "mn";
    }
  };

  componentDidMount() {
    const available = ["en", "mn"];
    const lang = window.localStorage.getItem("vsign_lang");

    if (!available.includes(lang)) {
      setStore("lang", "en");
      this.setState({ lang: "en" });
      window.localStorage.setItem("vsign_lang", "en");
    } else {
      setStore("lang", lang);
      this.setState({ lang });
    }

    this.props.updateCounter();
  }

  render() {
    let { user } = this.props;
    let { visible, lang } = this.state;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <div className="header main_header">
        <div className="header_search"></div>
        <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
          <Button size="small" type="dashed" onClick={this.changeLang}>
            {/* {getStore("lang")} */} {lang}
          </Button>
        </div>
        <div className="header_tooltip_container">
          <div className="header_tooltip flex_center">
            <SettingOutlined onClick={this.handleSettings} />
          </div>
        </div>
        <div className="user_info" ref={this.wrapperRef}>
          <div
            className="user_info_container flex_center"
            onClick={() => {
              this.setState({ visible: visible === 1 ? 0 : 1 });
            }}
          >
            {user.profile_image ? (
              <img src={user.profile_image} />
            ) : (
              user.kdss_name[0]
            )}
          </div>
          <div className="user_dropdown" is_show={visible}>
            <div className="user_dropdown_image flex_center">
              {user.profile_image ? (
                <img src={user.profile_image} />
              ) : (
                user.kdss_name[0]
              )}
            </div>
            <div className="user_dropdown_name">{user.kdss_name}</div>
            <div className="user_dropdown_mobile">{user.kdss_msisdn}</div>
            <div className="user_dropdown_email" color="1">
              {user.kdss_email}
            </div>
            <div
              className="user_dropdown_logout flex_center"
              bg_color="5"
              onClick={() => {
                custom.setCookie("token", "");
                window.location.hash = "#/login/mobile";
              }}
            >
              {Translate[ln].Header.Logout}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default example;
