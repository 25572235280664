import React from "react";
import { PreviewRoot } from "@/comps";
import { getStore, Loading, axios, custom } from "@/main";
import {
  CloseOutlined,
  CheckSquareOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios_root from "axios";
import { DateTime } from ".";
import { notification, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.user = getStore("user");
    this.roles = getStore("roles");
    this.colors = getStore("color");
  }
  state = {
    reload: 0,
    loading: false,
    docInfo: null,
    pageLoading: false,
    signers: null,
    signIndex: 0,
    cancelReason: false,
    sender: null,
  };
  parts = [];
  file = null;
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };
  funcs = {
    make_box: (per) => {
      let box = document.createElement("div");
      let inside = document.createElement("div");
      box.style.height = per.boxheight + "px";
      box.style.width = per.boxwidth + "px";
      box.style.background = per.bg_color;
      box.style.border = "1px solid " + per.bg_color;
      box.style.left = per.x1 + "px";
      box.style.bottom = per.y1 + "px";
      box.style.position = "absolute";
      box.className = "drop_box";
      inside.className = "drop_box_name";
      inside.style.width = per.boxwidth + "px";
      inside.style.overflowWrap = "break-word";
      inside.innerHTML = per.value ? per.value : per.type;
      box.appendChild(inside);
      if (per.type === "signature") {
        let inside_name = document.createElement("div");
        inside_name.className = "drop_box_sign";
        inside_name.innerHTML = per.footer;
        box.appendChild(inside_name);
      }

      //  if (per.type === "signature") {
      //  }
      return box;
    },
    init: () => {
      this.setState({ loading: true });
      let { params } = this.props;
      let getTxin = getStore("txin");
      let txin_id = params.txn_id;

      axios
        .post("client/api/doc/get/new", {
          docid: params.popfilter,
          txnid: txin_id ? txin_id : getTxin,
        })
        .then((e) => {
          this.status =
            e.data?.kdss_detail.status === "ARCHIVED" ||
            e.data?.kdss_detail.status === "COMPLETE"
              ? true
              : false;
          axios
            .post(
              "/client/api/doc/file/get",
              this.status
                ? {
                    docid: params.popfilter,
                    complete: true,
                  }
                : { docid: params.popfilter }
            )
            .then((res) => {
              this.file = custom.base64tou8(res.data);
            })
            .finally(() => {
              this.reload();
              this.setState({ loading: false });
            });
          this.setState({ cancelReason: e.data.kdss_status.cancelreason });
          this.setState({ sender: e.data.kdss_status.sender });
          e.data.kdss_detail?.participants?.map((per, i) => {
            per.key = per.key_c;
            per.bg_color = this.colors[i];
            per.coords = [];
            e.data.kdss_detail?.signatures &&
              e.data.kdss_detail?.signatures.map((per1, i1) => {
                if (per1.msisdn == per.msisdn) {
                  per.coords.push(per1);
                }
              });
            e.data.kdss_detail?.fields &&
              e.data.kdss_detail?.fields.map((per1, i1) => {
                if (per1.msisdn == per.msisdn) {
                  per.coords.push(per1);
                }
              });
          });

          let tmp = e.data.kdss_status?.signers;
          let first = tmp[e.data?.kdss_status?.signerindex - 1];
          this.setState({ signIndex: e.data?.kdss_status?.signerindex - 1 });

          if (e.data.kdss_detail?.status === "COMPLETE") {
            this.actionType = "complete";
          } else {
            if (first.userid === this.user.kdss_user) {
              this.actionType = first.actionrole;
            } else {
              this.actionType = "wait";
            }
          }
          this.parts = e.data.kdss_detail?.participants.sort((a, b) =>
            a.order >= b.order ? 1 : -1
          );
          this.setState({
            docInfo: e.data.kdss_detail,
            signers: e.data.kdss_status?.signers,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          notification.error({ message: err?.data?.message });
        });
    },
    close: () => {
      let { params } = this.props;
      window.location.hash = `#/${params.main}/${params.mainfilter}/0/0`;
      window.location.reload();
    },
    doc_sign: (id) => {
      let provider = custom.getCookie("provider");

      this.setState({ pageLoading: true });
      axios
        .post("client/api/doc/sign", { docid: id, provider })
        .then(({ data }) => {
          notification.success({ message: "Амжилттай!" });
          this.funcs.close();
        })
        .catch(({ data }) => notification.error({ message: data.message }))
        .finally(() => {
          this.setState({ loading: false });
          this.setState({ pageLoading: false });
        });
    },
    doc_reject: (id) => {
      this.setState({ pageLoading: true });
      axios
        .post("client/api/doc/reject", { docid: id })
        .then(({ data }) => {
          notification.success({ message: "Амжилттай!" });
          this.funcs.close();
        })
        .catch(({ data }) => notification.error({ message: data.message }))
        .finally(() => {
          this.setState({ loading: false });
          this.setState({ pageLoading: false });
        });
    },
    doc_approve: (id) => {
      this.setState({ pageLoading: true });
      axios
        .post("client/api/doc/approve", { docid: id })
        .then(({ data }) => {
          notification.success({ message: "Амжилттай!" });
          this.funcs.close();
        })
        .catch(({ data }) => notification.error({ message: data.message }))
        .finally(() => {
          this.setState({ loading: false });
          this.setState({ pageLoading: false });
        });
    },
  };
  renderTools = () => {
    let coords = [];
    let { parts } = this;
    let container = document.querySelector(".document_watermark_container");
    let droppable = document.createElement("div");
    droppable.className = "document_watermark_container_dropable";
    container.innerHTML = "";
    container.appendChild(droppable);
    parts.map((per, i) => {
      per.coords.map((per1, i1) => {
        per1.part_index = i;
        per1.coords_index = i1;
        per1.bg_color = per.bg_color;
        per1.footer = per.name || per.msisdn || per.email;
        coords.push(per1);
      });
    });
    coords.map((per, i) => {
      if (this.page === per.page) {
        let box = this.funcs.make_box(per);
        // conso
        container.appendChild(box);
      }
    });
    this.reload();
  };
  componentDidUpdate(pp, ps) {
    let { params } = this.props;
    if (pp.params.pop !== params.pop && params.pop === "preview") {
      this.funcs.init();
    }
  }
  componentDidMount() {
    let { params } = this.props;
    if (params.pop === "preview") {
      this.funcs.init();
    }
  }

  renderStatus = (status) => {
    let filters = {
      WAITING: {
        icon: "inbox",
        color: "orange",
      },
      ARCHIVED: {
        icon: "archive",
        color: "#ed3755",
      },
      COMPLETE: {
        icon: "check-square",
        color: "#968fff",
      },
      REJECTED: {
        icon: "window-close",
        color: "#8444F5",
      },
      SENT: {
        icon: "paper-plane",
        color: "#39A2DB",
      },
      CANCELLED: {
        icon: "ban",
        color: "#fc0328",
      },
    };

    return (
      <span style={{ marginLeft: "5px" }}>
        <FontAwesomeIcon
          icon={["fas", filters[status]?.icon]}
          color={filters[status]?.color}
        />
        <span
          style={{
            textTransform: "capitalize",
            marginLeft: "5px",
            fontWeight: "500",
          }}
        >
          {status?.toLowerCase()}
        </span>
      </span>
    );
  };
  renderButtons = (id) => {
    if (this.state.docInfo?.status === "WAITING")
      switch (this.actionType) {
        case "signer":
          return (
            <>
              {" "}
              <button
                className="btn "
                bg_color="5"
                onClick={() => {
                  this.funcs.doc_sign(id);
                }}
              >
                sign
              </button>
              <button
                className="btn "
                bg_color="6"
                onClick={() => this.funcs.doc_reject(id)}
              >
                reject
              </button>
            </>
          );
        case "approver":
          return (
            <>
              <button
                className="btn "
                bg_color="5"
                onClick={() => {
                  this.funcs.doc_approve(id);
                }}
              >
                approve
              </button>
              <button
                className="btn "
                bg_color="6"
                onClick={() => this.funcs.doc_reject(id)}
              >
                reject
              </button>
            </>
          );
        case "copy":
          return (
            <div className="preview_reminder">
              {" "}
              <InfoCircleOutlined />
              You have no permission to sign , approve or reject this document.
            </div>
          );
        case "complete":
          return;
        default:
          return (
            <div className="preview_reminder">
              {" "}
              <InfoCircleOutlined />
              Please wait other members.
            </div>
          );
      }
  };

  renderTitle = (i) => {
    let active = this.state?.signers[i];
    if (active?.status === "waiting") return active?.actionrole;
    else if (active?.status === "rejected") {
      let id = this.state?.docInfo?.rejected_by;
      return active?.msisdn === id.msisdn ? "Rejected" : active?.status;
    } else {
      switch (active?.actionrole) {
        case "approver":
          return "Approved";
        case "signer":
          return "Signed";
        default:
          return active?.actionrole;
      }
    }
  };

  renderViewer = () => {
    let { user, parts, file } = this;
    return this.status ? (
      <embed
        type="application/pdf"
        src={URL.createObjectURL(
          new Blob([this.file], {
            type: "application/pdf",
          })
        )}
        width="100%"
        height="100%"
      />
    ) : (
      <PreviewRoot
        roles={this.roles}
        parts={parts}
        user={user}
        hide_add={1}
        file={file}
        pageChange={(page) => {
          this.page = page;
          this.renderTools();
        }}
        onSuccess={() => {
          let element = document.createElement("div");
          let dropable = document.createElement("div");
          dropable.className = "document_watermark_container_dropable";
          element.className = "document_watermark_container";
          element.appendChild(dropable);
          document.querySelector(".react-pdf__Page").appendChild(element);
          this.page = 1;
          this.renderTools();
        }}
      />
    );
  };

  render() {
    let { params } = this.props;
    let { user, parts, file } = this;
    let id = this.state.docInfo?.docid;
    return (
      <>
        <div
          className="preview_container"
          is_show={params.pop === "preview" ? 1 : 0}
        >
          {params.pop === "preview" ? (
            <>
              <Loading loading={this.state.loading} />
              <div className="preview_header" bg_color="2">
                <div
                  className="upload_header_close flex_center"
                  onClick={this.funcs.close}
                  color="3"
                >
                  <CloseOutlined />
                </div>
                <span className="doc_info_title">
                  {" "}
                  <span>
                    {" "}
                    Doc preview: <b> {this.state.docInfo?.name} </b>
                  </span>
                  <span className="doc_info_date">
                    {" "}
                    <DateTime value={this.state.docInfo?.created_dt} />{" "}
                  </span>
                </span>
              </div>

              <div className="step1_container" style={{ flex: 1 }}>
                <div className="step1_detail">
                  <div className="step1_detail_status">
                    <span style={{ opacity: "0.7" }}>Status: </span>
                    {this.renderStatus(this.state.docInfo?.status)}
                    {/* <button onClick={()=>console.log(this.state.docInfo)}>asd</button> */}
                  </div>
                  <div className="step1_actions">{this.renderButtons(id)}</div>
                  <div className="step1_user_detail" style={{ marginTop: 20 }}>
                    <div className="step1_user_detail_title">
                      Sender
                      {this.state.docInfo?.status === "CANCELLED" && (
                        <div className="cancelled">cancelled</div>
                      )}
                    </div>
                    <div className="step1_user_detail_body">
                      <div className="user_dropdown_name">
                        {this.state.sender?.name}
                      </div>
                      <div className="user_dropdown_mobile">
                        {this.state.sender?.msisdn}
                      </div>
                      <div className="user_dropdown_email" color="1">
                        {this.state.sender?.email}
                      </div>
                      {this.state.cancelReason &&
                        this.state.docInfo?.status === "CANCELLED" && (
                          <>
                            <Divider
                              style={{ color: "rgb(223, 22, 22)" }}
                              plain
                              orientation="left"
                            >
                              Cancelled reason:
                            </Divider>
                            <div className="preview_reminder">
                              {" "}
                              <InfoCircleOutlined />
                              {this.state.cancelReason}
                            </div>
                            {/* <div className="filter_container"
                          style={{ color: "black", backgroundColor: "lightGray", padding: "2px"}}>
                            {this.state.cancelReason}
                          </div> */}
                          </>
                        )}
                    </div>
                    {this.state.docInfo?.status === "CANCELLED" && (
                      <div
                        style={{
                          justifyContent: "flex-end",
                          padding: "0 5px",
                          fontSize: "11px",
                          opacity: "0.7",
                          display: "flex",
                        }}
                      >
                        <span style={{ marginRight: "3px" }}>
                          Cancelled on{" "}
                        </span>{" "}
                        <DateTime value={this.state.docInfo?.modified_dt} />{" "}
                      </div>
                    )}
                  </div>
                  <div className="parts_scroll_area">
                    {parts.map((per, i) => {
                      return (
                        <div
                          key={i}
                          className="step1_user_detail"
                          style={{
                            marginTop: 20,
                            opacity: this.state?.signIndex === i ? "1" : "0.6",
                          }}
                          isSigned={this.state?.signIndex === i ? "1" : "0"}
                        >
                          <div
                            className="step1_user_detail_title"
                            style={{
                              background: per.bg_color,
                              textTransform: "capitalize",
                            }}
                          >
                            {this.renderTitle(i)}
                            {this.first?.order < per?.order && (
                              <span className="preview_waiting">Waiting</span>
                            )}
                            <div className="step1_user_signed">
                              <CheckSquareOutlined />
                            </div>
                          </div>
                          <div className="step1_user_detail_body">
                            <div className="user_dropdown_name">{per.name}</div>
                            <div className="user_dropdown_mobile">
                              {per.msisdn}
                            </div>
                            <div className="user_dropdown_email" color="1">
                              {per.email}
                            </div>
                          </div>
                          {this.state?.signers[i].signingtime && (
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                fontSize: "11px",
                                padding: "0 3px",
                              }}
                            >
                              <DateTime
                                value={this.state.signers[i].signingtime}
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="step3_body">
                  <div className="absfull">{this.renderViewer()}</div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        {this.state.pageLoading && (
          <div className="page_loader">
            <LoadingOutlined />
          </div>
        )}
      </>
    );
  }
}

export default example;
