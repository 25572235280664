import React from "react";
import { getStore } from "../main";
import { Translate } from "../translate";

class example extends React.Component {
  componentDidMount() {}
  render() {
    let { p } = this.props;
    let key = p.main;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return <div className="main_dock">{Translate[ln].Main[key]}</div>;
  }
}

export default example;
