import React from "react";
import { getStore } from "@/main";
import {
  EditFilled,
  UserOutlined,
  MailOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown } from "antd";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.roles = getStore("roles");
  }
  icon_map = {
    pen: <EditFilled />,
    user: <UserOutlined />,
    envelope: <MailOutlined />,
  };
  state = {
    selected: { actionrole: "signer", icon: "pen", key: 0, name: "SIGN" },
  };
  render() {
    let { per } = this.props;
    if (!per) {
      per = 0;
    }
    let info = this.roles[per];
    let menu = (
      <Menu>
        {this.roles.map((per, i) => {
          return (
            <Menu.Item
              key={per.key}
              onClick={() => {
                console.log("per!", per);
                this.props.onChange(per);
                this.setState({ selected: per });
              }}
            >
              <div className="checked_role_per">
                {this.icon_map[per.icon]}&nbsp; {per.name}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return (
      <div className="checked_role">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className="checked_role_per_active">
          {this.icon_map[this.state.selected.icon]}&nbsp; {this.state.selected?.name.toUpperCase()}{" "}
            &nbsp;
            <CaretDownOutlined />
          </div>
        </Dropdown>
      </div>
    );
  }
}

export default example;
