import React from "react";
import { Alert } from "antd";

const PasswordIndicator = ({
  validity: { minChar, number, specialChar, capital },
}) => {
  return (
    <Alert
      className="recover-ant-text"
      style={{ marginTop: 10 }}
      message="Password requirements:"
      description={
        <ul>
          <PasswordIndicatoritem isValid={number} text="At least one number" />
          <PasswordIndicatoritem
            isValid={specialChar}
            text="At least one symbol: ~!@#$%..."
          />
          <PasswordIndicatoritem
            isValid={capital}
            text="At least one capital letter"
          />
          <PasswordIndicatoritem
            isValid={minChar}
            text="Minimum 8 characters length"
          />
        </ul>
      }
      type={minChar && number && specialChar && capital ? "success" : "warning"}
      showIcon
    />
  );
};
const PasswordIndicatoritem = ({ isValid, text }) => {
  const spanColor = isValid ? "green" : "red";
  const color = { color: spanColor };
  return <li style={color}>{text}</li>;
};

export default PasswordIndicator;
