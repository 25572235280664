import React from "react";
import {
  HomeOutlined,
  FolderOpenOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { getStore } from "../main";
import { Translate } from "../translate";

class PerMenu extends React.Component {
  render() {
    let { per, active, handleChange, name } = this.props;
    return (
      <a
        href={per.href}
        className="left_menu_per"
        active_color={active}
        onClick={() => handleChange && handleChange()}
      >
        <div className="left_menu_per_icon">{per.icon}</div>
        <div className="left_menu_per_name">{name}</div>
      </a>
    );
  }
}

class example extends React.Component {
  state = { reload: 0, flipped: false };
  hashchange = () => {
    let { hash } = window.location;
    let { active_menu } = this;
    if (hash.indexOf(active_menu) === -1) {
      let exp = hash.split("/");
      this.active_menu = `#/${exp[1]}`;
    }
  };
  active_menu = "#/home";
  left_menus = [
    {
      key: 1,
      name: "home",
      active: "#/home",
      href: "#/home/0/0/0",
      icon: <HomeOutlined />,
    },
    {
      key: 2,
      name: "manage",
      active: "#/manage",
      href: "#/manage/0/0/0",
      icon: <FolderOpenOutlined />,
    },
    {
      key: 3,
      name: "settings",
      active: "#/settings",
      href: "#/settings/0/0/0",
      icon: <SettingOutlined />,
    },
    {
      key: 4,
      name: "drafts",
      active: "#/templates",
      href: "#/templates/0/0/0",
      icon: (
        <span>
          <SolutionOutlined />
        </span>
      ),
    },
    // {key:5,name:"Test",active:"#/konva",href:"#/konva/0/0/0",icon:<span ><SolutionOutlined /></span>}
  ];
  funcs = {
    new: () => {
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/upload/0`;
    },
    handleChange: () => {
      this.setState({ flipped: !this.state.flipped });
    },
  };

  render() {
    this.hashchange();
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <>
        <div className="left_menu_hamburger">
          <div className="header" onClick={this.funcs.handleChange}>
            <img alt="vsign_logo" src="/logo.png" className="mobile_logo" />
          </div>
        </div>
        <div
          className="mobile_header_overlay"
          style={{
            transform: `translateX(${this.state.flipped ? "0" : "-100%"})`,
          }}
        >
          <div onClick={this.funcs.handleChange} className="mobile_close_x">
            X
          </div>
          <div className="mobile_overlay_item">
            <div
              className="btn button_flex"
              bg_color="5"
              onClick={this.funcs.new}
            >
              {" "}
              {Translate[ln].Main.new} <PlusCircleOutlined />
            </div>
          </div>
          {this.left_menus.map((per, i) => {
            let active = 0;
            if (per.active === this.active_menu) {
              active = 1;
            }
            return (
              <PerMenu
                key={per.key}
                per={per}
                active={active}
                handleChange={this.funcs.handleChange}
              />
            );
          })}
        </div>
        <div className="left_menu">
          <div className="header">
            <div className="header_logo">
              <div>
                <img src="/logo.png" alt="logo" />
              </div>
              <div className="header_logo_name">Signatuready.com</div>
            </div>
          </div>
          <div className="left_menu_container">
            <div className="left_menu_button">
              <div
                className="btn button_flex"
                bg_color="5"
                onClick={this.funcs.new}
              >
                {Translate[ln].Main.new} <PlusCircleOutlined />
              </div>
            </div>
            {this.left_menus.map((per, i) => {
              let active = 0;
              if (per.active === this.active_menu) {
                active = 1;
              }
              return (
                <PerMenu
                  key={per.key}
                  per={per}
                  active={active}
                  name={Translate[ln].Main[[per.name]]}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default example;
