import {
  notification,
  Tabs,
  Alert,
  Button,
  Upload,
  Form,
  Modal,
  Checkbox,
} from "antd";
import React from "react";
import { getStore, setStore } from "../../main/global";
import { Loading, axios } from "@/main/";
import {
  SettingOutlined,
  HighlightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { UserStampModal } from "../UserStampModal";
import { Translate } from "../../translate";

const { TabPane } = Tabs;

class example extends React.Component {
  state = {
    loading: { mail: false, pass: false },
    mail: { disabled: true, value: "" },
    pass: { disabled: true, old: "", new1: "", new2: "" },
    err: { mail: "", old: "", new1: "", new2: "" },
    loading_axios: false,
    fileList: [],
    alertMsg: "",
    uploading: false,
    imageData: [],
    disable: false,
    previewVisible: false,
    previewTitle: "",
    handleCancel: "",
    previewImage: "",
    checkBoxData: [
      { label: "Signature", value: "signature" },
      { label: "Seal", value: "eseal" },
      { label: "Initial", value: "initials" },
    ],
    isOpen: false,
  };

  constructor(props) {
    super();
    this.userData = getStore("user");

    let tmpKdss = this.state.mail;
    tmpKdss.value = this.userData;
    this.setState({ mail: tmpKdss });
  }

  getInfo = () => {
    axios
      .post("client/api/user/info", {})
      .then(({ data }) => {
        setStore("user", data.detail);
        this.userData = data.detail;
      })
      .catch(({ data }) => console.log(data.message));
  };

  handleMailChange = () => {
    let tmp = this.state.mail;
    tmp.disabled = !this.state.mail.disabled;
    this.setState({ mail: tmp });
  };

  handlePassChange = () => {
    let tmp = this.state.pass;
    tmp.disabled = !this.state.pass.disabled;
    this.setState({ pass: tmp });
  };

  saveMailChanges = () => {
    let loading = this.state.loading;
    loading.mail = true;
    this.setState({ loading: loading });
    axios
      .patch("client/api/user/update", {
        kdss_email: this.state.mail.value,
        profile_image: "",
      })
      .then((res) => {
        notification.success({ message: "Success" });
      })
      .catch(({ data }) => notification.error({ message: data.message }))
      .finally(() => {
        loading.mail = false;
        this.setState({ loading: loading });
        this.handleMailChange();
        this.getInfo();
      });
  };

  savePassChanges = () => {
    let loading = this.state.loading;
    loading.pass = true;
    this.setState({ loading: loading });

    axios
      .patch("client/api/user/password/change", {
        old_password: this.state.pass.old,
        password: this.state.pass.new1,
      })
      .then((res) => notification.success({ message: "Success" }))
      .catch(({ data }) => notification.error({ message: data.message }))
      .finally(() => {
        loading.pass = false;
        this.setState({ loading: loading });
        this.handlePassChange();
        this.getInfo();
      });
  };

  mailChangeHandler = (value) => {
    let tmp = this.state.mail;
    let tmpError = this.state.err;
    tmp.value = value;
    if (value === "") {
      tmpError.mail = "Please fill your email";
    } else {
      tmpError.mail = "";
    }
    this.setState({ mail: tmp, err: tmpError });
  };

  passChangeHandler = (key, value) => {
    let tmp = this.state.pass;
    let tmpError = this.state.err;
    tmp[key] = value;

    if (tmp[key].length === 0) {
      tmpError[key] = "Please do not leave blank.";
    } else {
      tmpError[key] = "";
      if (tmp.new1 !== tmp.new2) {
        tmpError.new2 = "Repeat password does not matching!";
      } else {
        tmpError.new2 = "";
      }
    }
    this.setState({ err: tmpError, pass: tmp });
  };

  cancelHandler = (key) => {
    let tmp = this.state.err;
    tmp[key] = "";
    if (key === "new2") {
      this.handlePassChange();
    } else {
      this.handleMailChange();
    }
    this.setState({ err: tmp });
  };

  cancelBttnStyle = {
    marginLeft: "10px",
    background: "#eee",
    color: "black",
  };

  handleImageList = () => {
    this.setState({ loading_axios: true });
    axios
      .get("client/api/user/image/list", {})
      .then((e) => {
        const { list } = e.data;

        if (list.images.length > 0) {
          let images = [];
          list.images.map((val) => {
            axios
              .post("client/api/user/image/detail", {
                imageid: val.imageid,
              })
              .then(({ data }) => {
                images = [...images, { base64: data.data[1], id: val.imageid }];
                this.setState({ imageData: images });
              });
          });
        }
        this.setState({
          loading_axios: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading_axios: false,
          alertMsg: "Not upload signature",
        });
      });
  };

  tabsOnChange = (key) => {
    if (key === "2") this.handleImageList();
  };

  uploadOnChange = (e) => {
    this.setState({ alertMsg: "" });

    let file = "";
    if (e.fileList.length > 0) {
      if (e.fileList[0].size > 400000) {
        notification.error({ message: "Файлын хэмжээ их байна." });
        return false;
      }

      file = e.fileList[0];
      file.status = "done";
    }

    this.setState({ fileList: file ? [file] : [] });
  };

  getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

      reader.onerror = (error) => reject(error);
    });

  onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    const url = file.url || file.preview;
    const title =
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    this.setState({
      previewVisible: true,
      previewTitle: title,
      previewImage: url,
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    let {
      loading_axios,
      fileList,
      alertMsg,
      imageData,
      previewVisible,
      previewTitle,
      previewImage,
      uploading,
    } = this.state;

    const FORM_BTN = {
      wrapperCol: {
        span: 16,
        offset: 16,
      },
    };

    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <>
        <br></br>
        <Tabs
          defaultActiveKey="1"
          className="settings-tabs-margin"
          type="card"
          onChange={this.tabsOnChange}
        >
          <TabPane
            tab={
              <span>
                <SettingOutlined />
                {Translate[ln].Settings.Menu1}
              </span>
            }
            key="1"
          >
            <div className="settings_page_wrapper">
              <div
                className="settings_box"
                loading={this.state.loading.pass && "1"}
              >
                <div className="settings_box_title">
                  {" "}
                  {Translate[ln].Settings.AccountInfo}
                </div>
                <div className="settings_box_item">
                  <div className="settings_item_label">
                    {" "}
                    {Translate[ln].Settings.Oldpass}
                  </div>
                  <input
                    error={this.state.err.old.length > 0 && "1"}
                    type="password"
                    disabled={this.state.pass.disabled}
                    placeholder="input your old password"
                    onChange={(e) =>
                      this.passChangeHandler("old", e.target.value)
                    }
                  />
                </div>
                <div className="settings_box_item">
                  <div className="settings_item_label">
                    {Translate[ln].Settings.Newpass}
                  </div>
                  <input
                    error={this.state.err.new1.length > 0 && "1"}
                    type="password"
                    disabled={this.state.pass.disabled}
                    placeholder="input your new password"
                    onChange={(e) =>
                      this.passChangeHandler("new1", e.target.value)
                    }
                  />
                </div>
                <div className="settings_box_item">
                  <div className="settings_item_label">
                    {Translate[ln].Settings.RepNewpass}
                  </div>
                  <input
                    error={this.state.err.new2.length > 0 && "1"}
                    type="password"
                    disabled={this.state.pass.disabled}
                    placeholder="repeat your new password"
                    onChange={(e) =>
                      this.passChangeHandler("new2", e.target.value)
                    }
                  />
                  {this.state.pass.disabled ? (
                    <button
                      onClick={this.handlePassChange}
                      className="settings_right_align"
                      bg_color="5"
                    >
                      {Translate[ln].Settings.Changepass}
                    </button>
                  ) : (
                    <div className="settings_right_align settings_flex_bttns">
                      <button
                        onClick={this.savePassChanges}
                        disabled={this.state.err.new2.length > 0}
                        bg_color="5"
                      >
                        Save changes
                      </button>
                      <button
                        onClick={() => this.cancelHandler("new2")}
                        style={this.cancelBttnStyle}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                {this.state.err.new2.length > 0 && (
                  <div className="settings_box_error">
                    {" "}
                    <div>{this.state.err.new2}</div>{" "}
                  </div>
                )}
              </div>
              <div
                className="settings_box"
                loading={this.state.loading.mail && "1"}
              >
                <div className="settings_box_title">
                  {Translate[ln].Settings.ContactInfo}
                </div>
                <div className="settings_box_item">
                  <div className="settings_item_label">
                    {" "}
                    {Translate[ln].Settings.Email}
                  </div>
                  <input
                    onChange={(e) => this.mailChangeHandler(e.target.value)}
                    error={this.state.err.mail.length > 0 && "1"}
                    type="email"
                    defaultValue={this.userData?.kdss_email}
                    disabled={this.state.mail.disabled}
                    placeholder="input your email"
                  />
                  {this.state.mail.disabled ? (
                    <button
                      onClick={this.handleMailChange}
                      className="settings_right_align"
                      bg_color="5"
                    >
                      {Translate[ln].Settings.ChangeEmail}
                    </button>
                  ) : (
                    <div className="settings_right_align settings_flex_bttns">
                      <button
                        onClick={this.saveMailChanges}
                        disabled={this.state.err.mail.length > 0}
                        bg_color="5"
                      >
                        Save changes
                      </button>
                      <button
                        onClick={() => this.cancelHandler("mail")}
                        style={this.cancelBttnStyle}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                {this.state.err.mail.length > 0 && (
                  <div className="settings_box_error">
                    {" "}
                    <div>{this.state.err.mail}</div>{" "}
                  </div>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <HighlightOutlined />
                {Translate[ln].Settings.Menu2}
              </span>
            }
            key="2"
          >
            <div className="settings_page_wrapper">
              <div className="upload-image-button">
                <Button
                  onClick={() => this.setState({ isOpen: true })}
                  type="primary"
                >
                  {Translate[ln].Settings.Upload}
                </Button>
              </div>
              {alertMsg && (
                <>
                  <div className="upload-image-sign">
                    <Alert message={alertMsg} type="success" />
                  </div>
                  <br></br>
                </>
              )}

              {imageData.length > 0 && (
                <div className="image-data-list">
                  {imageData.map((val, i) => {
                    let img64 = `data:image/png;base64, ${val.base64}`;
                    return (
                      <StampIMG
                        img64={img64}
                        key={"stamp_img_" + i}
                        id={val.id}
                        refresh={this.handleImageList}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </TabPane>
        </Tabs>

        <Loading loading={loading_axios} />

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>

        <UserStampModal
          visible={this.state.isOpen}
          onCancel={() => this.setState({ isOpen: false })}
          userID={this.userData?.kdss_user}
          next={this.handleImageList}
        />
      </>
    );
  }
}

const StampIMG = ({ img64, id, refresh }) => {
  const deleteImage = async () => {
    const { kdss_user } = getStore("user");

    const res = await axios.post(
      `/admin/api/user/deleteimg/${kdss_user}/${id}`
    );

    const { data } = res.data;

    refresh();

    notification.success({ message: "Deleted." });
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        type="outline"
        shape="circle"
        icon={<DeleteOutlined />}
        size="small"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
        // ghost
        danger
        onClick={deleteImage}
      />
      <img className="image-uploaded-list" src={img64} alt="" />
    </div>
  );
};

export default example;
