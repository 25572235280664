import React from "react";
import { Document, Page } from "react-pdf";
import { axios, custom, Loading } from "@/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";
import Drag from "./upload/Drag";
import { createRef } from "react";
import {
  CheckSquareOutlined,
  CloseCircleOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Stage, Layer, Text } from "react-konva";
import { StickyNote } from "./sticky/StickyNote";
import { Translate } from "../translate";
import { getStore } from "../main";

class example extends React.Component {
  constructor() {
    super();
    this.overlayRef = createRef();
  }
  state = {
    drag_start: false,
    reload: 0,
    active_i: 0,
    activepage: 1,
    numPages: 0,
    rotate: 0,
    scale: 100,
    visible: false,
    transform: { scale: 1, rotate: 0 },
    max: {
      w: 0,
      h: 0,
    },
  };
  pageChange = (page) => {
    if (this.props.pageChange) {
      this.props.pageChange(page);
    }
  };

  intersect = function (a, b) {
    return !(a.x2 <= b.x1 || b.x2 <= a.x1 || a.y2 <= b.y1 || b.y2 <= a.y1);
  };
  calc_intersect = (active) => {
    let boxes = [...this.props.boxes];
    boxes.push({
      left: 10,
      top: 966,
      page: this.state.activepage,
      style: { height: 18, width: 255 },
    });

    let i = 0;
    let inter = false;
    while (boxes.length > i) {
      let per = boxes[i];
      if (i !== this.state.active_i && per.page === this.state.activepage) {
        let box1 = {
          x1: per.left,
          x2: per.left + per.style.width,
          y1: per.top,
          y2: per.top + per.style.height,
        };
        let box2 = {
          x1: active.left,
          x2: active.left + active.style.width,
          y1: active.top,
          y2: active.top + active.style.height,
        };
        if (this.intersect(box1, box2)) {
          inter = true;
          break;
        }
      }
      i++;
    }
    return inter;
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };

  mouseMove = (e) => {
    let stamps = this.props.boxes;
    let { offsetX, offsetY } = e.nativeEvent;
    let active = stamps[this.state.active_i];
    let per = { ...{}, ...active };
    let inter = false;
    let act = this.active_ref;

    if (per && act) {
      let { clientWidth, clientHeight } = this.overlayRef.current;

      let x1 = offsetX - parseInt(act.getAttribute("shiftx"));
      let y1 = offsetY - parseInt(act.getAttribute("shifty"));
      if (clientWidth < x1 + act.clientWidth) {
        // Parent Right side limitation
        x1 = clientWidth - act.clientWidth;
      }
      if (clientHeight < y1 + act.clientHeight) {
        // Parent Bottom side limitation
        y1 = clientHeight - act.clientHeight;
      }
      if (x1 < 0) {
        // Parent Left side limitation
        x1 = 0;
      }
      if (y1 < 0) {
        // Parent Top side limitation
        y1 = 0;
      }
      per.left = x1;
      per.top = y1;
      inter = this.calc_intersect(per); // Intersect check
      if (inter) {
        per = { ...{}, ...active };
        per.left = x1;
        inter = this.calc_intersect(per); // Fixed left side
        if (inter) {
          per = { ...{}, ...active };
          per.top = y1;
          inter = this.calc_intersect(per); // Fixed top side
          if (inter) {
            per = { ...{}, ...active };
          }
        }
      }
    }

    per.page = this.state.activepage;
    stamps[this.state.active_i] = per;
    this.reload();
  };
  mouseUp = (e) => {
    let container = this.overlayRef.current;
    container = container.getBoundingClientRect();
    let stamps = this.props.boxes;
    let per = { ...stamps[this.state.active_i] };

    per.x1 = per.left;
    per.y1 = container.height - per.top - per.height;
    // per.x2 = per.left + per.width;
    // per.y2 = container.height - per.top;

    stamps[this.state.active_i] = per;
    this.props.setBoxes(stamps);
    this.overlayRef.current.style.display = "none";
  };
  mouseDown = (ref, i) => {
    this.props.setActive && this.props.setActive(i);
    console.log("mouse down", i);
    this.overlayRef.current.style.display = "block";
    this.active_ref = ref;
    this.setState({ active_i: i });
  };

  // onDragEnd = (event, per) =>{
  //   let container = document.querySelector(".app");
  //   container = container.getBoundingClientRect()

  //   console.log(event, per)
  //   let tmp = {...per, left: event.clientX - container.left, top: event.clientY - container.top, key: per.name+ this.state.reload, unique: per.name + this.state.reload,};
  //   this.stamps.push(tmp);
  //   this.reload()
  //   console.log(this.stamps)

  // }

  setMaxBounds = () => {
    const container = document.querySelector(".upload_steps_body");
    const sides = container.getBoundingClientRect();
    this.setState({ max: { w: sides.width, h: sides.height } });
  };
  // componentDidMount() {
  //   const setMaxBounds = () => {
  //     const container = document.querySelector(".upload_steps_body");
  //     console.log("container", container.clientHeight, container.clientWidth);
  //   };

  //   setTimeout(setMaxBounds, 500);
  // }
  componentDidUpdate(prevProps) {
    if (this.props.boxes?.length !== prevProps.boxes?.length) {
      let boxes = this.props.boxes;
      if (this.props.boxes?.length > 0)
        boxes[this.props.boxes?.length - 1].page = this.state.activepage;
      this.props.setBoxes(boxes);
    }

    if (prevProps.file !== this.props.file) {
      console.log("file changed here!", this.props.file);
      this.setMaxBounds();
    }
  }

  infoHandler = () => {
    this.setState({ visible: !this.state.visible });
  };

  updateHandler = (e, i) => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    let y = e.clientY - rect.top;
    const tmp = this.props.boxes;
    tmp.map((item, idx) => {
      if (i === idx) {
        item["width"] = rect.width;
        item["height"] = rect.height;
        item["value"] = e.target.value;
      }
      return item;
    });
    this.props.setBoxes(tmp);
  };

  pdfTransformer = (scale) => {
    const t = { ...this.state.transform };
    if (scale > 0.2 && scale < 3) {
      t.scale = scale;
    }
    // if (rotate) {
    //   t.rotate = rotate;
    // }

    const container = document.querySelector(".my_pdf");
    container.style.zoom = t.scale;

    this.setState({ transform: t });
  };

  render() {
    let { user, parts, roles, lockInputs } = this.props;
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <div className="review_root">
        <div className="review_pdf_fixed_header">
          <div className="review_info_trigger">
            <FontAwesomeIcon icon="info-circle" onClick={this.infoHandler} />
          </div>
          <div className="review_pdf_tools">
            <div
              className="review_pdf_container_back"
              onClick={() => {
                if (this.state.activepage > 1) {
                  this.setState({ activepage: this.state.activepage - 1 });
                  this.pageChange(this.state.activepage - 1);
                }
              }}
            >
              <FontAwesomeIcon size="3x" icon="caret-left" />
            </div>
            <div className="review_pdf_container_total">
              {Translate[ln].NewDoc.Customizations.Page}: &nbsp;&nbsp;
              <Input
                onFocus={(event) => {
                  event.target.select();
                }}
                style={{ width: 40, textAlign: "center" }}
                value={this.state.activepage}
                onChange={(data) => {
                  let val = data.target.value * 1;
                  if (!isNaN(val) && val > 0 && this.state.numPages >= val) {
                    this.setState({ activepage: val });
                    this.pageChange(val);
                  }
                }}
              />
              &nbsp; of &nbsp;<b>{this.state.numPages}</b>
            </div>
            <div
              className="review_pdf_container_forward"
              onClick={() => {
                if (this.state.numPages > this.state.activepage) {
                  this.setState({ activepage: this.state.activepage + 1 });
                  this.pageChange(this.state.activepage + 1);
                }
              }}
            >
              <FontAwesomeIcon size="3x" icon="caret-right" />
            </div>

            <div className="pdf_controller">
              {this.state.transform.scale !== 1 && (
                <Button
                  icon={<CloseCircleOutlined />}
                  shape="circle"
                  onClick={() => this.pdfTransformer(1)}
                />
              )}
              <Button
                icon={<ZoomInOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(this.state.transform.scale + 0.2)
                }
                type="primary"
              />
              <Button
                icon={<ZoomOutOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(this.state.transform.scale - 0.2)
                }
                type="primary"
              />
              {/* <Button
                icon={<RotateLeftOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(null, this.state.transform.rotate - 90)
                }
              />
              <Button
                icon={<RotateRightOutlined />}
                shape="circle"
                onClick={() =>
                  this.pdfTransformer(null, this.state.transform.rotate + 90)
                } */}
            </div>
            {!this.props.hide_add
              ? [
                  <div
                    key="1"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.rotate + 1;
                      if (ind > 3) {
                        ind = 0;
                      }
                      this.setState({ rotate: ind });
                    }}
                  >
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon="redo" />
                  </div>,
                  <div
                    key="2"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.rotate - 1;
                      if (ind < 0) {
                        ind = 3;
                      }
                      this.setState({ rotate: ind });
                    }}
                  >
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon="undo" />
                  </div>,
                  <div
                    key="3"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.scale - 20;
                      if (ind < 20) {
                        ind = 20;
                      }
                      this.setState({ scale: ind });
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon="search-minus"
                    />
                  </div>,
                  <div key="4" className="review_pdf_container_action">
                    <input
                      style={{ width: 60, textAlign: "center" }}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      value={this.state.scale}
                      onChange={(data) => {
                        let val = data.target.value * 1;
                        if (!isNaN(val) && val > 0 && val < 300) {
                          this.setState({ scale: val });
                        }
                      }}
                    />
                  </div>,
                  <div
                    key="5"
                    className="review_pdf_container_action"
                    onClick={() => {
                      let ind = this.state.scale + 20;
                      if (ind > 300) {
                        ind = 300;
                      }
                      this.setState({ scale: ind });
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 20 }}
                      icon="search-plus"
                    />
                  </div>,
                ]
              : null}
          </div>
          {this.props.onClose ? (
            <div
              className="review_pdf_fixed_close"
              onClick={this.props.onClose}
            >
              <FontAwesomeIcon icon="times" size="3x" />
            </div>
          ) : null}
        </div>

        <div className="upload_steps_body" style={{ paddingTop: 30 }}>
          {this.state.visible && (
            <div className="opacity_overlay" onClick={this.infoHandler} />
          )}
          <div
            className="preview_info_overlay"
            style={{
              transform: `translateX(${this.state.visible ? "0" : "-100vw"})`,
            }}
          >
            <div className="preview_info_contents">
              <div className="step1_actions">
                <button className="btn " bg_color="5">
                  sign
                </button>
                <button className="btn " bg_color="6">
                  reject
                </button>
              </div>
              <div className="step1_user_detail" style={{ marginTop: 20 }}>
                <div className="step1_user_detail_title">
                  {Translate[ln].NewDoc.Preview.Sender}
                </div>
                <div className="step1_user_detail_body">
                  <div className="user_dropdown_name">{user?.kdss_name}</div>
                  <div className="user_dropdown_mobile">
                    {user?.kdss_msisdn}
                  </div>
                  <div className="user_dropdown_email" color="1">
                    {user?.kdss_email}
                  </div>
                </div>
              </div>
              {parts?.map((per, i) => {
                const { name } = roles[per.role];
                return (
                  <div
                    key={i}
                    className="step1_user_detail"
                    style={{ marginTop: 20 }}
                  >
                    <div
                      className="step1_user_detail_title"
                      style={{ background: per.bg_color }}
                    >
                      {Translate[ln].NewDoc.Preview[name]}
                      <div
                        className="step1_user_signed"
                        isSigned={per?.is_signed}
                      >
                        <CheckSquareOutlined />
                      </div>
                    </div>
                    <div className="step1_user_detail_body">
                      <div className="user_dropdown_name">{per?.name}</div>
                      <div className="user_dropdown_mobile">{per?.msisdn}</div>
                      <div className="user_dropdown_email" color="1">
                        {per.email}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div onClick={this.infoHandler} className="preview_close_x">
                X
              </div>
            </div>
          </div>
          <div
            className="review_pdf_container"
            style={{
              maxWidth: this.state.max.w === 0 ? "100%" : this.state.max.w,
            }}
          >
            <Document
              id="pdf-cont"
              file={this.props.file}
              onLoadSuccess={({ numPages }) => {
                this.setState({ numPages: numPages });
                if (this.props.onSuccess) {
                  this.props.onSuccess();
                }
              }}
              style={{ background: "red" }}
              className="my_pdf"
            >
              <Page
                height={1000}
                pageNumber={this.state.activepage}
                rotate={this.state.rotate * 90}
                scale={this.state.scale / 100}
              >
                <div className="boxes_wrapper">
                  <div
                    className="boxes_drag_area"
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {this.props.boxes?.map(
                      (per, i) =>
                        per.page === this.state.activepage && (
                          <Drag
                            dragstart={(ref) => {
                              this.mouseDown(ref, i);
                            }}
                            {...per}
                            key={i + "_" + per.drag_txt}
                            index={i}
                            disabled={per.uid !== this.props.user?.user_id}
                            locked={this.props.locked}
                            value={per.value}
                            updateHandler={(ref) => {
                              this.updateHandler(ref, i);
                            }}
                            lockInputs={lockInputs}
                          />
                        )
                    )}
                    <div className="drag_footer_box">Footer text</div>
                    <div
                      id="app_overlay"
                      onMouseMove={this.mouseMove}
                      onMouseUp={this.mouseUp}
                      ref={this.overlayRef}
                      onClick={() => console.log("mouse click")}
                    />
                  </div>
                </div>
              </Page>
            </Document>
            {/* <div id="app_overlay" /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default example;
