import { Modal, Button } from "antd";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";

const types = {
  eseal: 1 / 1,
  signature: 5 / 2,
  initials: 1 / 1,
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    console.log(canvas.toDataURL());
    canvas.toBlob((file) => {
      console.log("fie", file);
      //   resolve(URL.createObjectURL(file));
      resolve(canvas.toDataURL());
    }, "image/png");
  });
}

export const ImageCropModal = ({ isOpen, onClose, file, type, saveResult }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const rotation = 0;

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        file,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);

      saveResult && saveResult(croppedImage);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Modal visible={isOpen} onCancel={onClose} footer={null}>
      <div>
        {" "}
        Cropping image as{" "}
        <span>
          <b>{type}</b>
        </span>
      </div>
      <div style={{ position: "relative", minHeight: "300px" }}>
        <Cropper
          image={file}
          crop={crop}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          aspect={types[type] || 1}
          zoom={zoom}
          onZoomChange={setZoom}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingTop: "15px",
        }}
      >
        <Button onClick={showCroppedImage} type="primary">
          Done
        </Button>
      </div>
    </Modal>
  );
};
