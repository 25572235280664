import React from "react";
import { LeftMenu, Header, Dock } from "@/comps";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { getStore, Preview } from "@/main";
import { HomeRouter, ManageRouter } from "@/comps/home/";
import { SettingsRouter } from "@/comps/settings/";
import { Upload } from "@/comps/upload/";
import ShareRoot from "./ShareRoot";
import DownloadRoot from "./DownloadRoot";
import TemplateRoot from "./template";
import Konva from "./konva";

class example extends React.Component {
  constructor(props) {
    super();
    this.user = getStore("user");
  }

  state = {
    counter: 0,
  };

  render() {
    let { params } = this.props.match;
    return (
      <>
        <div className="home_container relafull">
          <LeftMenu params={params} />
          <div className="main_content">
            <Header
              user={this.user}
              updateCounter={() =>
                this.setState({ counter: this.state.counter + 1 })
              }
            />
            <div className="main_content_container">
              <Dock p={params} />
              <Switch>
                <Route path="/home" component={HomeRouter} />
                <Route path="/manage/:filter" component={ManageRouter}>
                  {/* <ManageRouter reloaded={this.state.reloaded}/> */}
                </Route>
                <Route path="/settings" component={SettingsRouter} />
                <Route
                  path="/templates/:popfilter/:tmpTrigger/:id"
                  component={TemplateRoot}
                />
                <Route path="/konva/" component={Konva} />
              </Switch>
            </div>
          </div>
        </div>
        <Upload params={params} setParams={this.setParams} />
        <Preview params={params} />
        <ShareRoot params={params} />
        <DownloadRoot params={params} />
      </>
    );
  }
}

export default example;
