import {
    PARTS_RESET,
    PARTS_ADD,
    ROLE_CHANGE,
    PARTS_REMOVE,
    SET_FOCUS,
    BOX_ADD,
} from '../type'


export const partsReset = () => {
    return{
        type: PARTS_RESET,
        payload: null
    }
}

export const partsAdd = (val) => {
    return{
        type: PARTS_ADD,
        payload: val
    }
}

export const roleChange = (val) => {
    return{
        type: ROLE_CHANGE,
        payload: val
    }
}

export const partsRemove = (val) => {
    return{
        type: PARTS_REMOVE,
        payload: val
    }
}

export const setFocus = (val) => {
    return{
        type: SET_FOCUS,
        payload: val
    }
}

export const boxAdd = (val) => {
    return {
        type: BOX_ADD,
        payload: val
    }
}