import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "@/App.jsx";
import { pdfjs } from "react-pdf";
import { library } from "@fortawesome/fontawesome-svg-core";
import reportWebVitals from "./reportWebVitals";
import {
  faInbox,
  faEdit,
  faTrash,
  faPaperPlane,
  faExclamationCircle,
  faClock,
  faExclamationTriangle,
  faCheck,
  faSearch,
  faTimes,
  faCheckSquare,
  faSquare,
  faPlus,
  faUser,
  faUserFriends,
  faStar,
  faColumns,
  faPen,
  faStamp,
  faCalendar,
  faEnvelope,
  faBuilding,
  faBriefcase,
  faHeading,
  faAddressBook,
  faCog,
  faUserCog,
  faCaretRight,
  faCaretLeft,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faCaretDown,
  faCheckCircle,
  faInfoCircle,
  faBan,
  faArchive,
  faWindowClose,
  faClipboardCheck,
  faTree,
  faLeaf,
  faTint,
  faScroll,
  faFont,
  faSubscript,
  faLink,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faInbox,
  faEdit,
  faTrash,
  faPaperPlane,
  faExclamationCircle,
  faClock,
  faExclamationTriangle,
  faCheck,
  faSearch,
  faTimes,
  faCheckSquare,
  faSquare,
  faPlus,
  faUser,
  faUserFriends,
  faStar,
  faColumns,
  faPen,
  faStamp,
  faCalendar,
  faEnvelope,
  faBuilding,
  faBriefcase,
  faHeading,
  faAddressBook,
  faCog,
  faUserCog,
  faCaretRight,
  faCaretLeft,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faCaretDown,
  faCheckCircle,
  faInfoCircle,
  faBan,
  faArchive,
  faWindowClose,
  faClipboardCheck,
  faTree,
  faLeaf,
  faTint,
  faScroll,
  faFont,
  faSubscript,
  faLink,
  faImages
);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
