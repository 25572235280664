import React from "react";
import { getStore, Loading, setStore, axios } from "@/main";
import {
  Button,
  Modal,
  notification,
  Table,
  Form,
  Input,
  Popconfirm,
} from "antd";
import {
  UserAddOutlined,
  TeamOutlined,
  DeleteOutlined,
  UserSwitchOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { createRef } from "react";
import { Translate } from "../../translate";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = createRef();
  }
  state = {
    active: {},
    active_i: 0,
    loading: true,
    page: "1",
    contacts: [],
  };

  getContacts = (val) => {
    axios
      .post("client/api/user/contacts/list", {
        page: 0,
        pper: 30,
        search: val ?? "",
      })
      .then((e) => {
        e.data.list.map((per, i) => {
          per.key = i + 1;
        });
        this.setState({ contacts: e.data.list });
        this.setState({ loading: false });
      })
      .catch((data) => {
        this.setState({ contacts: [] });
        this.setState({ loading: false });
        notification.error({ message: data.message });
      });
  };

  getSysContacts = (val) => {
    this.setState({ loading: true });
    axios
      .post("client/api/user/contacts", {
        page: 0,
        pper: 10,
        search: val ?? "",
      })
      .then((e) => {
        e.data.contacts.map((per, i) => {
          per.key = i + 1;
        });
        this.sysContacts = e.data.contacts.sort((a, b) => {
          return b.id - a.id;
        });
        this.setState({ contacts: this.sysContacts });
        this.setState({ loading: false });
      })
      .catch((data) => {
        this.setState({ contacts: [] });
        this.setState({ loading: false });
        notification.error({ message: data.message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  deleteContact = (id) => {
    this.setState({ loading: true });
    axios
      .post("client/api/user/contacts/remove", { id: id })
      .then(({ data }) => {
        notification.success({ message: data.message });
        this.getContacts();
      })
      .catch((err) => notification.error({ message: err.message }))
      .finally(() => this.setState({ loading: false }));
  };

  modal = {
    className: "nopaddingwrapper selectcontact",
    visible: false,
    onCancel: () => {
      this.props.onCancel();
    },
  };
  contact_table = {
    loading: false,
    columns: [
      { title: "Name", dataIndex: "name" },
      { title: "MSISDN", dataIndex: "msisdn" },
      { title: "Email", dataIndex: "email_address" },
      {
        title: "Action",
        render: (el) => (
          <div className="contact_delete">
            <Popconfirm
              title="Are you sure to delete this contact ?"
              okText="Delete"
              onConfirm={() => this.deleteContact(el.id)}
            >
              <DeleteOutlined style={{ cursor: "pointer" }} />
            </Popconfirm>
          </div>
        ),
      },
    ],

    border: false,
    size: "small",
    pagination: false,
    active_row: {},
  };

  addToFav = (el) => {
    let data = {
      email_address: el.kdss_email,
      msisdn: el.kdss_msisdn,
      name: el.kdss_name,
    };

    this.createContact(data, true);
  };

  sysContact_table = {
    loading: false,
    columns: [
      { title: "Name", dataIndex: "kdss_name" },
      { title: "MSISDN", dataIndex: "kdss_msisdn" },
      { title: "Email", dataIndex: "kdss_email" },
      {
        title: "Action",
        render: (el) => (
          <div
            style={{
              textAlign: "center",
              color: "blueviolet",
              cursor: "pointer",
            }}
          >
            <StarOutlined color="blue" onClick={() => this.addToFav(el)} />
          </div>
        ),
      },
    ],
    border: false,
    size: "small",
    pagination: false,
    active_row: {},
  };

  init = () => {
    this.contact_table.active_row = {};
    this.setState({
      active: {},
      active_i: 0,
    });
    this.setState({ loading: true });
    if (this.state.contacts.length === 0) {
      this.getContacts();
    } else {
      this.setState({ loading: false });
    }
  };
  componentDidUpdate(pp, ps) {
    if (pp.visible !== this.props.visible) {
      this.init();
    }
    if (ps.page !== this.state.page) {
      this.state.page === "1" ? this.getContacts() : this.getSysContacts();
    }
  }

  items = [
    {
      icon: <UserAddOutlined />,
      label: "New",
      val: "0",
    },
    {
      icon: <TeamOutlined />,
      label: "Fav",
      val: "1",
    },
    {
      icon: <UserSwitchOutlined />,
      label: "Sys",
      val: "2",
    },
  ];

  pageHandler = (val, flag) => {
    this.setState({ page: val });
  };

  createContact = (data, flag) => {
    this.setState({ loading: true });
    axios
      .post("client/api/user/contacts/add", data)
      .then((res) => {
        console.log(res);
        this.setState({ page: "1" });
        this.getContacts();
        this.formRef.current?.resetFields();
        notification.success({ message: res.data.message, duration: 4 });
        flag && this.setState({ page: "1" });
      })
      .catch((err) => {
        notification.error({ message: err.message, duration: 4 });
        console.log(err);
      })
      .finally(() => this.setState({ loading: false }));
  };

  checkFields = () => {
    this.formRef.current.validateFields().then((val) => {
      this.createContact(val);
    });
  };

  renderPage = (obj) => {
    switch (this.state.page) {
      case "0":
        return (
          <div className="add_contacts">
            <Form
              layout="vertical"
              ref={this?.formRef}
              onFinish={this.createContact}
            >
              <Form.Item
                name="name"
                rules={[{ required: true, message: "required." }]}
                label={obj["Name"]}
              >
                <Input placeholder="John Doe" />
              </Form.Item>
              <Form.Item
                name="msisdn"
                rules={[{ required: true, message: "required." }]}
                label={obj["MSISDN"]}
              >
                <Input placeholder="12341234" />
              </Form.Item>
              <Form.Item
                name="email_address"
                rules={[{ required: true, message: "required." }]}
                label={obj["Email"]}
              >
                <Input placeholder="John@gmail.com" type="email" />
              </Form.Item>
              <Button htmlType="submit" type="primary"></Button>
            </Form>
          </div>
        );
      case "1":
        return (
          <div style={{ overflow: "auto", maxHeight: "500px" }}>
            <Table
              {...{
                ...this.contact_table,
                columns: this.contact_table.columns.map((e) => ({
                  ...e,
                  title: obj[e.title],
                })),
              }}
              dataSource={this.state.contacts}
              pagination={this.pagination}
              onChange={(e) => this.setState({ pagination: e })}
              rowSelection={{
                type: "radio",
                onChange: (a, i) => {
                  this.contact_table.active_row = i[0];
                },
              }}
            />
          </div>
        );
      default:
        return (
          <div style={{ overflow: "auto", maxHeight: "500px" }}>
            <Table
              {...{
                ...this.sysContact_table,
                columns: this.sysContact_table.columns.map((e) => ({
                  ...e,
                  title: obj[e.title],
                })),
              }}
              dataSource={this.state.contacts}
              rowSelection={{
                type: "radio",
                onChange: (a, i) => {
                  let tmp = i[0];
                  tmp.email_address = tmp.kdss_email;
                  tmp.msisdn = tmp.kdss_msisdn;
                  tmp.name = tmp.kdss_name;

                  this.sysContact_table.active_row = tmp;
                },
              }}
            />
          </div>
        );
    }
  };

  render() {
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");
    return (
      <Modal
        footer={
          this.state.page === "0" ? (
            <Button type="primary" onClick={this.checkFields}>
              {Translate[ln].NewDoc.Participants.Create}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                this.props.onOk(
                  this.state.page === "1"
                    ? this.contact_table.active_row
                    : this.sysContact_table.active_row
                );
              }}
            >
              {Translate[ln].NewDoc.Participants.Select}
            </Button>
          )
        }
        {...this.modal}
        visible={this.props.visible ? true : false}
        width={"max-content"}
        title={Translate[ln].NewDoc.Participants.SelectContact}
      >
        <Loading loading={this.state.loading} />
        <div style={{ padding: "10px" }}>
          <Input.Search
            placeholder={Translate[ln].NewDoc.Participants.placeholder3}
            onSearch={(val) => this.getContacts(val)}
            disabled={this.state.page === "0"}
            allowClear
          />
        </div>
        <div className="contacts">
          <div className="contacts_sidebar">
            {this.items.map((e, i) => (
              <div
                onClick={() => this.pageHandler(e.val)}
                key={i + "sb"}
                className={`sidebar_item ${
                  this.state.page === e.val ? "active" : "non_active"
                }`}
                role="button"
              >
                {e.icon}
                {Translate[ln].NewDoc.Participants[e.label]}
              </div>
            ))}
          </div>
          <div style={{ position: "relative" }} className="contacts_content">
            {this.renderPage(Translate[ln].NewDoc.Participants)}
          </div>
        </div>
      </Modal>
    );
  }
}

export default example;
