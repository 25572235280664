import React from "react";
import { PreviewRoot } from "@/comps";
import { getStore } from "@/main";
import { Translate } from "../../translate";

class example extends React.Component {
  constructor(props) {
    super(props);
    this.user = getStore("user");
    this.roles = getStore("roles");
  }
  state = {
    reload: 0,
    selectedStamp: 0,
  };
  reload = () => {
    this.setState({ reload: this.state.reload + 1 });
  };
  funcs = {
    finish: () => {
      let p = this.props.params;
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/${
        p.popfilter * 1 + 1
      }`;
    },
    make_box: (per) => {
      console.log(" working make make box", per);
      let box = document.createElement("div");
      let inside = document.createElement("div");
      box.style.height = per.height + "px";
      box.style.width = per.width + "px";
      box.style.background = per.color.replace("1)", " 0.7)");
      box.style.border = "1px solid " + per.color;
      box.style.left = per.x1 + "px";
      box.style.top = per.top + "px";
      box.style.position = "absolute";
      box.className = "drop_box";
      inside.className = "drop_box_name";
      inside.style.width = per.boxwidth + "px";
      inside.style.overflowWrap = "break-word";

      if (
        ["yourStamp", "yourSignature", "yourInitials"].includes(per.unique_key)
      ) {
        inside.innerHTML = per.shortname;
      } else
        inside.innerHTML = `${
          per.value
            ? per.value
            : per.url
            ? `<div class="yourSignature">${per.drag_txt}</div>`
            : per.drag_txt
        }`;

      if (per.url) {
        box.style.backgroundColor = "#ffffff";
        box.style.backgroundImage = "url('" + per.url + "')";
        box.style.backgroundRepeat = "no-repeat";
        box.style.backgroundSize = "contain";
        box.style.backgroundPositionX = "right";
      }
      box.appendChild(inside);
      if (per.unique_key === "signature") {
        let inside_name = document.createElement("div");
        inside_name.className = "drop_box_sign";
        inside_name.innerHTML = per.username;
        box.appendChild(inside_name);
      }
      return this.page === per.page ? box : null;
    },
  };
  renderTools = () => {
    let coords = [];
    let { parts } = this.props.data;
    let container = document.querySelector(".document_watermark_container");
    let droppable = document.createElement("div");
    droppable.className = "document_watermark_container_dropable";
    container.innerHTML = "";
    container.appendChild(droppable);
    // parts.map((per,i)=>{
    //     // per.coords.map((per1,i1)=>{
    //     //     per1.part_index = i;
    //     //     per1.coords_index = i1;
    //     //     per1.bg_color = per.bg_color;
    //     //     per1.footer = per.name || per.msisdn || per.email
    //     //     coords.push(per1);
    //     // })
    //     coords[i] = { part_index:  per.part_index, coords_index : i, bg_color: per.color.replace(")", "0.7)"), footer : per.username };
    // })
    this.props.stamps?.map((per, i) => {
      if (this.page === per.page) {
        let box = this.funcs.make_box(per);
        container.appendChild(box);
      }
    });
    this.reload();
  };
  componentDidUpdate(pp, ps) {
    if (pp.trigger !== this.props.trigger) {
      this.funcs.finish();
    }
  }
  render() {
    let { data, params } = this.props;
    let { file, parts } = data;
    let { user } = this;
    if (params.popfilter != 3) {
      return <div />;
    }
    let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

    return (
      <div className="step1_container relafull">
        <div className="step1_detail">
          <div className="step1_user_detail" style={{ marginTop: 20 }}>
            <div className="step1_user_detail_title">
              {Translate[ln].NewDoc.Preview.Sender}{" "}
            </div>
            <div className="step1_user_detail_body">
              <div className="user_dropdown_name">{user.kdss_name}</div>
              <div className="user_dropdown_mobile">{user.kdss_msisdn}</div>
              <div className="user_dropdown_email" color="1">
                {user.kdss_email}
              </div>
            </div>
          </div>
          <div className="parts_scroll_area">
            {parts.map((per, i) => {
              return (
                <div
                  key={i}
                  className="step1_user_detail"
                  style={{ marginTop: 20 }}
                >
                  <div
                    className="step1_user_detail_title"
                    style={{
                      background: per.bg_color,
                      textTransform: "capitalize",
                    }}
                  >
                    {Translate[ln].NewDoc.Preview[per?.actionrole]}
                  </div>
                  <div className="step1_user_detail_body">
                    <div className="user_dropdown_name">{per.name}</div>
                    <div className="user_dropdown_mobile">{per.msisdn}</div>
                    <div className="user_dropdown_email" color="1">
                      {per.email}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="step3_body">
          <div className="absfull">
            <PreviewRoot
              hide_add={1}
              file={file}
              pageChange={(page) => {
                this.page = page;
                this.renderTools();
              }}
              onSuccess={() => {
                let element = document.createElement("div");
                let dropable = document.createElement("div");
                dropable.className = "document_watermark_container_dropable";
                element.className = "document_watermark_container";
                element.appendChild(dropable);
                document.querySelector(".react-pdf__Page").appendChild(element);
                this.page = 1;
                this.renderTools();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default example;
