import React from "react";
import { Upload } from "antd";
import { Loading, axios } from "@/main";
import { notification } from "antd";

class example extends React.Component {
  state = {
    loading: false,
  };
  funcs = {
    finish: (file, docs_id, txn_id) => {
      let p = this.props.params;
      this.props.data.file = file;
      this.props.onChange(file, docs_id, txn_id);
      window.location.hash = `#/${p.main}/${p.mainfilter}/${p.pop}/${
        p.popfilter * 1 + 1
      }`;
    },
    uploaded: (file) => {
      let bodyFormData = new FormData();
      bodyFormData.append("file", file);
      this.setState({ loading: true });
      axios
        .post("client/api/doc/create", bodyFormData)
        .then((e) => {
          this.setState({ loading: false });
          this.funcs.finish(file, e.data.docid, e.data.txnid);
        })
        .catch((e) => {
          this.setState({ loading: false });
          notification["error"]({
            message: "Алдаа гарлаа",
            description: e.data.message,
          });
        });
      return false;
    },
  };
  render() {
    let { params } = this.props;
    if (params.popfilter != 0) {
      return <div />;
    }
    return (
      <>
        <Loading loading={this.state.loading} />
        <div className="step0_container relafull flex_center">
          <div className="upload_section flex_center">
            <div>
              <img src="/file-add.svg" />
            </div>
            <div className="upload_section_title">Drop Document here</div>
            <div className="upload_section_body">or Click here</div>
            <div className="second_circle" />
            <Upload.Dragger
              accept="application/pdf"
              multiple={false}
              beforeUpload={this.funcs.uploaded}
              className="upload_dragger absfull"
            />
          </div>
        </div>
      </>
    );
  }
}

export default example;
