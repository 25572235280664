import React, { useRef } from "react";
import { axios, custom, Loading, reset, getStore, setStore } from "@/main";
import { Route, Switch, Redirect } from "react-router-dom";
import { notification } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
class EmailLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state.type_value = custom.getUrlParameter("email");
  }
  state = {
    type: "email",
    type_value: "",
    password: "",
    provider: "mpki",
  };

  render() {
    return (
      <div>
        <input
          key="email"
          className="login_field"
          type="text"
          placeholder="Email address"
          value={this.state.type_value}
          onChange={(data) => {
            this.setState({ type_value: data.target.value });
          }}
        />
        <input
          key="password"
          style={{ marginTop: 10, width: "100%" }}
          className="login_field"
          type="password"
          placeholder="password"
          value={this.state.password}
          onChange={(data) => {
            this.setState({ password: data.target.value });
          }}
        />
        <div style={{ paddingTop: "20px" }}>
          <div style={{ opacity: "0.5" }}>Provider :</div>

          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "10px" }}>
              <input
                type="radio"
                value="mpki"
                name="provider"
                onChange={(e) => this.setState({ provider: e.target.value })}
                defaultChecked={true}
              />{" "}
              Mobile ID
            </div>
            <div>
              <input
                type="radio"
                value="gasr"
                name="provider"
                onChange={(e) => this.setState({ provider: e.target.value })}
              />
              App ID
            </div>
          </div>
        </div>
        <button
          className="login_button"
          // disabled={this.props.isVerified}
          onClick={() => {
            this.props.onsubmit(this.state);
            this.props.resetchaptcha();
          }}
        >
          Continue
        </button>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/mobile">
            Login with Mobile Number
          </a>
        </div>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/forgot">
            Forgot Password
          </a>
        </div>
      </div>
    );
  }
}

class MobileLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state.type_value = custom.getUrlParameter("msisdn");
    if (process.env.NODE_ENV === "development") {
      this.state.type_value = "35847004004";
    }
  }
  state = {
    type_value: "",
    type: "mobile",
    provider: "mpki",
  };
  render() {
    return (
      <div>
        <input
          className="login_field"
          type="text"
          maxLength="12"
          placeholder="Mobile number"
          value={this.state.type_value}
          onChange={(data) => {
            this.setState({ type_value: data.target.value });
          }}
        />

        <div style={{ paddingTop: "20px" }}>
          <div style={{ opacity: "0.5" }}>Provider :</div>

          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: "10px" }}>
              <input
                type="radio"
                value="mpki"
                name="provider"
                onChange={(e) => this.setState({ provider: e.target.value })}
                defaultChecked={true}
              />{" "}
              Mobile ID
            </div>
            <div>
              <input
                type="radio"
                value="gasr"
                name="provider"
                onChange={(e) => this.setState({ provider: e.target.value })}
              />
              App ID
            </div>
          </div>
        </div>
        <div
          className="login_button"
          onClick={() => {
            this.props.onsubmit(this.state);
            this.props.resetchaptcha();
          }}
        >
          Continue
        </div>
        {/* <div style={{textAlign:"center",paddingTop:20}}><a className="login_link" target='_blank' href="#/recover?email=munkhbayar.mugi@gmail.com&token=leFDLoMsIlv570YJIYQ4bxSvTM0K3SFF">recooo</a></div> */}
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/email">
            Login with email
          </a>
        </div>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/forgot">
            Forgot Password
          </a>
        </div>
      </div>
    );
  }
}

class example extends React.Component {
  constructor(props) {
    super(props);
    this.logo = "/logo.png";
    this._reCaptchaRef = React.createRef();
  }
  state = {
    loading: false,
    callback: "not fired",
    value: [],
    load: false,
    expired: true,
  };
  onsubmit = (json) => {
    custom.setCookie("provider", json.provider);

    if (json.type !== "mobile") {
      json.captcha = this.state.value;
      if (!this.state.value?.length) {
        notification["error"]({
          message: "Анхаар!",
          description: "Би робот биш товч дээр дарна уу.",
        });
        return;
      }
    }
    this.setState({ loading: true });
    axios
      .post("/client/auth/login", json)
      .then((e) => {
        custom.setCookie("token", e.data.token);
        reset();
        notification["success"]({
          message: "Амжилттай",
          description: e.data.message,
        });
        let red = custom.getCookie("redirect_url");
        custom.setCookie("redirect_url", "");
        setStore("user", e.data.detail);
        if (red && window.location.hash !== red) {
          window.location.hash = red;
        } else {
          window.location.hash = "#/home";
        }
      })
      .catch((e) => {
        notification["error"]({
          message: "Алдаа гарлаа",
          description: e.data.message,
        });
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };
  submit = (data) => {
    this.onsubmit(data);
  };
  recover = (data) => {
    if (data.type !== "mobile") {
      data.captcha = this.state.value;
      if (!this.state.value?.length) {
        notification["error"]({
          message: "Анхаар!",
          description: "Би робот биш товч дээр дарна уу.",
        });
        return;
      }
    }
    this.setState({ loading: true });
    axios
      .post("/client/auth/recover", data)
      .then((e) => {
        notification["success"]({
          message: "Амжилттай",
          description: "Бүртгэлтэй мэйлээ шалгана уу.", //e.data.message
        });
      })
      .catch((e) => {
        notification["error"]({
          message: "Алдаа гарлаа",
          description: e.data.message,
        });
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };
  componentDidUpdate(pp, ps) {
    if (pp.match.params.type !== this.props.match.params.type) {
      this.type = this.props.match.params.type;
    }
  }
  componentDidMount() {
    this.type = this.props.match.params.type;
    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);
  }
  handleChange = (value) => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
  };
  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };
  resetchaptcha = () => {
    this.setState({
      callback: "not fired",
      value: [],
      expired: true,
    });
  };
  render() {
    const { value, callback, load, expired } = this.state || {};
    return (
      <div className="login_container">
        <Loading loading={this.state.loading} />
        <div className="login">
          <div style={{ textAlign: "center" }}>
            <img className="login_image" src={this.logo} alt="logo" />
          </div>
          <div className="login_form">
            <Switch>
              <Route path="/login/email">
                <div className="login_title">
                  Please log in to your Signatuready.com account
                </div>
                <EmailLogin
                  onsubmit={this.submit}
                  resetchaptcha={this.resetchaptcha}
                  isExpired={this.state.expired}
                />
                <br />
                {load && (
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    ref={this._reCaptchaRef}
                    sitekey="6LdaJr0eAAAAALCSKgUTcO3z6fZaYMdWsXUf-F7_"
                    onChange={this.handleChange}
                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                  />
                )}
              </Route>
              <Route path="/login/mobile">
                <div className="login_title">
                  Please log in to your Signatuready.com account
                </div>
                <MobileLogin
                  onsubmit={this.submit}
                  resetchaptcha={this.resetchaptcha}
                />
              </Route>
              <Route path="/login/forgot">
                <div className="login_title">
                  Please enter your email address.
                </div>
                <ForgotPass
                  recover={this.recover}
                  isVerified={this.state.isVerified}
                  resetchaptcha={this.resetchaptcha}
                />
                <br />
                {load && (
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    ref={this._reCaptchaRef}
                    sitekey="6LdaJr0eAAAAALCSKgUTcO3z6fZaYMdWsXUf-F7_"
                    onChange={this.handleChange}
                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                  />
                )}
              </Route>
              <Redirect to="/login/email" />
            </Switch>
          </div>
        </div>
        <div className="login_footer">
          VSign.mn © 2021, Inc. All rights reserved.
        </div>
      </div>
    );
  }
}
class ForgotPass extends React.Component {
  constructor(props) {
    super(props);
    this.state.type_value = custom.getUrlParameter("forgot");
  }
  state = {
    type: "email",
    type_value: "",
    password: "",
  };
  render() {
    return (
      <div>
        <input
          key="email"
          className="login_field"
          type="text"
          placeholder="Email address"
          value={this.state.type_value}
          onChange={(data) => {
            this.setState({ type_value: data.target.value });
          }}
        />
        {/* <input key="password" style={{marginTop:10, width: "100%"}} className="login_field" type="password" placeholder="password" value={this.state.password} onChange={(data)=>{
                    this.setState({password:data.target.value})
                }} /> */}
        <button
          className="login_button"
          disabled={this.isVerified}
          onClick={() => {
            this.props.recover(this.state);
            this.props.resetchaptcha();
          }}
        >
          Continue
        </button>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/mobile">
            Login with Mobile Number
          </a>
        </div>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <a className="login_link" href="#/login/email">
            Login with email
          </a>
        </div>
      </div>
    );
  }
}
export default example;
