import React, { useEffect, useState } from "react";
import { axios, DateTime, getStore } from "@/main/";
import {
  Divider,
  Drawer,
  Upload,
  Steps,
  Input,
  notification,
  Button,
  Dropdown,
} from "antd";
import {
  DeleteOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  EllipsisOutlined,
  BarsOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { CheckRole, DragDrop, SelectContacts } from "@/comps/upload/";
import { PreviewRoot } from "@/comps";
import moment from "moment";
import TemplateRoot from "./TemplateRoot";
import Menu from "rc-menu/lib/Menu";

import { useDispatch, useSelector } from "react-redux";
import { partsList, partsAdd, resetParts } from "../../store/actions";
import { Translate } from "../../translate";

const Template = ({ data, deleteAction, setLoading }) => {
  const [visible, setVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);

  let ln = typeof getStore("lang") === "object" ? "en" : getStore("lang");

  const useTemplate = () => {
    window.location.hash = `#/templates/1/0/${data.id}`;
  };

  const handleVisible = () => {
    setShareVisible(!shareVisible);
    setVisible(false);
  };

  const onOk = (data) => {
    if (!data.id) {
      notification.error({
        message: "Please select from Favourite contacts!",
        duration: 4,
      });
      return 1;
    }
    shareContact(data.id);
  };

  const shareContact = (user_id) => {
    setLoading && setLoading(true);
    axios
      .post("client/api/doc/template/share", {
        user_id: user_id,
        template_id: data.id,
      })
      .then(({ data }) => {
        notification.success({ message: data.message, duration: 3 });
        handleVisible();
      })
      .catch((err) => {
        notification.error({ message: err.data.message, duration: 4 });
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  // const movies = useSelector( state => state.parts )
  //   const dispatch = useDispatch()
  //   dispatch(partsList())
  const parts = useSelector((state) => state.draft.parts);

  const dispatch = useDispatch();
  const getParticipants = () => {
    dispatch(partsAdd());
  };

  return (
    <div className="template">
      <div className="template_banner">
        <SelectContacts
          visible={shareVisible}
          onCancel={handleVisible}
          onOk={onOk}
        />
        <Dropdown
          onVisibleChange={() => setVisible(!visible)}
          overlay={
            <Menu>
              <div className="template_drop">
                <div onClick={handleVisible}>
                  {" "}
                  <ShareAltOutlined />
                  {Translate[ln].Drafts.View}
                </div>
              </div>
            </Menu>
          }
          visible={visible}
          trigger={["click"]}
        >
          <div className="temp_ellipsis">
            <BarsOutlined />
          </div>
        </Dropdown>
        <div className="template_title">
          <span>{data?.name ?? "Title"}</span>
        </div>
        <span
          style={{
            fontSize: "12px",
            textTransform: "none",
            fontWeight: "lighter",
          }}
        >
          <DateTime value={moment(data.create_date?.date)} />
        </span>
      </div>

      <div className="template_info">
        <div className="template_info_double">
          <div className="template_info_double">
            <span> {Translate[ln].Drafts.Participants}:</span>
            <span>{data?.signers_count}</span>
          </div>
          {/* <div className="template_info_double">
            <span>Used:</span>
            <span>{data?.used_count}</span>
          </div> */}
        </div>
        {/* <div>
                    Components : Signature, Date, Company names
                </div> */}
        <div className="template_info_footer">
          <button onClick={() => deleteAction(data.id)}>
            <DeleteOutlined />
          </button>
          <button onClick={useTemplate}>
            {Translate[ln].Drafts.View} <ArrowRightOutlined />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Template;
