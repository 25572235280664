import React, { useRef, useState } from "react";
import { SelectContacts, CheckRole } from "@/comps/upload/";
import { Button, Form, Input } from "antd";
import { ContactsOutlined, ContactsTwoTone } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactTemplate = ({ data, onChange, defaults }) => {
  const [visible, setVisible] = useState(false);
  const formRef = useRef();

  const visibleHandler = () => {
    setVisible(!visible);
  };

  const onOk = (data) => {
    formRef.current.setFieldsValue({
      email_address: data.email_address,
      msisdn: data.msisdn,
    });
    visibleHandler();
    changeHandler("msisdn", data.msisdn);
    changeHandler("email", data.email_address);
  };

  const changeHandler = (key, value) => {
    if (onChange && value !== undefined) onChange(key, value);
  };

  return (
    <div className="contact_template">
      <SelectContacts visible={visible} onCancel={visibleHandler} onOk={onOk} />
      <div style={{ background: data.bg_color }} className="ct_highlight">
        <span> {data?.name} </span>
        <div> {data?.actionrole} </div>
      </div>

      <Form ref={formRef} onChange={(e) => e.target}>
        <div style={{ padding: "10px 10px 0 10px" }}>
          <Form.Item name="msisdn">
            <Input
              type="text"
              placeholder="MSISDN"
              onChange={(e) => changeHandler("msisdn", e.target.value)}
              defaultValue={defaults?.msisdn ?? ""}
            />
          </Form.Item>
          <Form.Item name="email_address">
            <Input
              defaultValue={defaults?.email ?? ""}
              onChange={(e) => changeHandler("email", e.target.value)}
              placeholder="Email"
              type="email"
              addonAfter={
                <FontAwesomeIcon
                  icon={["fas", "address-book"]}
                  className="ct_trigger"
                  onClick={visibleHandler}
                />
              }
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default ContactTemplate;
