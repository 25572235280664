import { Input } from "antd";
import { ChangeRole } from "@/comps/upload/";
import { getStore } from "@/main/";
import { useDispatch, useSelector } from "react-redux";
import { partsRemove } from '../../store/actions/'


const Participant = (props) => {
  const colors = getStore("color");
  const parts = useSelector(state => state.draft.parts)
  const part = useSelector(state => state.draft.parts[props.index])
  const dispatch = useDispatch()

  const remove = (val) => {
    if(parts && parts.length > 1) dispatch(partsRemove(val))
  }

  return (
    <div className="user_comp">
      <div
        className="left_highlight"
        style={{ background: colors[props.index] }}
      ></div>
      <ChangeRole index={part.key} />
      <Input
        placeholder={`#${props.index + 1} ${ part.name.charAt(0).toUpperCase() + part.name.slice(1)}`}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <div onClick={ ()=> remove(part.key) } className="del_x button_flex">
        x
      </div>
    </div>
  );
};

export default Participant;