import {
    PARTS_ADD,
    PARTS_RESET,
    ROLE_CHANGE,
    PARTS_REMOVE,
    SET_FOCUS,
} from '../type'
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 10 });

const initialState  = {
    parts: [
        {
            role: "SIGN",
            key: uid(),
            msisdn: "",
            email: "",
            user_id: 1,
            color: "rgba(0, 92, 185, 0.7)",
            bg_color: "rgba(0, 92, 185, 0.7)",
            actionrole: "signer",
            can_sign: true,
            move_sign: false,
            coords: [],
            name: "Signer",
            disable: true,
        },
    ],
    focused:{}
}

export default function(state=initialState, action){
    switch(action.type){
        case PARTS_RESET:
            return initialState
        case PARTS_ADD:
            return {...state, parts:[...state.parts, action.payload]}
        case PARTS_REMOVE:
            return {...state, parts: state.parts.filter(item => item.key != action.payload)}
        case ROLE_CHANGE:
            const {index, value} = action.payload
            const idx = state.parts.findIndex(item => item.key === index)
            let tmp = state.parts[idx]
            tmp.role = value.role
            tmp.actionrole = value.actionrole
            if (value.name === "SIGN") {
                tmp.name = 'Signer'
            } else if(value.name === "APPROVE") {
                tmp.name = 'Approver'
            } else {
                tmp.name = 'Copy receiver'
            }
            if (value.name !== "SIGN") {
                tmp.can_sign = false;
            }
            return {...state, parts:[
                ...state.parts.slice(0, idx),
                tmp,
                ...state.parts.slice(idx + 1),
            ]}
        case SET_FOCUS:
            return {...state, focused: action.payload}
        default:
            return state
    }
}