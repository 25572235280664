import React from 'react';

class example extends React.Component{
    render(){
        let { loading } = this.props;
        return (
            <div className="loading absfull flex_center" trans="500" loading={loading?1:0}>
                LOADING
            </div>
        );
    }
}

export default example;