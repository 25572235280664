import React from 'react';
import { Preview } from "@/main";
import { useLocation } from 'react-router-dom'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Example = () => {

    const query =  useQuery()
    const doc_id =  query.get("doc_id")
    const txn_id =  query.get("txn_id")

    const props =  {
        main: "manage",
        mainfilter: "0",
        pop: "preview",
        popfilter: doc_id,
        txn_id: txn_id
    }

    return <Preview params={props} />
}

export default Example;